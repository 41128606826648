import React, { useState } from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import './header.scss'
import SearchBar from '../../components/searchBar/searchBar';
import UserSetting from '../userSetting/userSetting';

const Header = (props) => {
    const [search, setSearch] = useState('')
    return (
        <Grid container className='headerContainer'>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>

                {/* <SearchBar placeholder={'Search...'} handleChange={setSearch} /> */}
            </Grid>
            <Grid item xs={8} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>

                <UserSetting/>
            </Grid>
        </Grid>
    );
};


export default Header;