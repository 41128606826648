import React, { useEffect, useState } from "react";
import { Box, Typography } from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { useSelector } from "react-redux";
import { filterName } from "../../utils/Utils";

const WearhouseProductDetail = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { state } = props
    const { packSizeList } = useSelector(state => state.filler)


    const [ProductDetails, setProductDetails] = useState([])

    const tableHeader = ['Product Name', 'Expiry To', 'Pack Size', 'Quantity']
    const verifyParam = ['productName', 'expiryTo', 'packSizeValue', 'productDetailQty']

    useEffect(() => {
        setIsLoading(true)
        let arr = []
        state.subProduct.forEach((ele) => {
            const obj = { ...ele }
            obj['productName'] = state.productName;
            obj['packSizeValue'] = filterName(packSizeList, obj.packSize_ID)
            arr.push(obj)
        })
        setIsLoading(false)
        setProductDetails(arr)

    }, [state])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Wearhouse Product Detail</Typography>
            </Box>
            <Card style={{ flex: 1 }}>
                <TableContainer tableHeader={tableHeader}
                    data={ProductDetails}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                />
            </Card>
        </>
    );
};

export default WearhouseProductDetail;