import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Autocomplete, TextField } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../components/searchBar/searchBar";
import moment from "moment";
import { createPo } from "../../store/actions/po";
import add from '../../assets/add.png';
import { filterName } from "../../utils/Utils";
import SelectedProduct from "../../components/selected";
import { getAllWarehouseProductPaging } from "../../store/actions/warehouse";
import ModalButton from "../../components/modalButton/modalButton";
import FillRequestData from "./fillRequestData";
import SaveButton from "../../components/saveButton/saveButton";

const RFTS = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth)
    const navigation = useNavigate()

    const { wearhouseList, vendorList, packSizeList } = useSelector(state => state.filler)
    const { warehouseProductPaging, wearhouseProductPagingcount } = useSelector(state => state.warehouse)

    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [Products, setProducts] = useState([])
    const [ProductsList, setProductsList] = useState([])
    const [orderList, setOrderList] = useState([])
    const [search, setSearch] = useState('')
    const [wearhouse, setWearhouse] = useState('')

    const tableHeader = ['DIN Number', 'Vendor', 'Product Name', 'Strenght', 'Available Quantity']
    const verifyParam = ['diN_Master', 'vendorValue', 'productName', 'mg', 'productQty']
    const selectedHeader = ['DIN Number', 'Vendor', 'Product Name', 'Strenght', 'Pack Size', 'Available Quantity', 'Quantity Required']
    const selectedParam = ['diN_Master', 'vendorValue', 'productName', 'mg', 'PackSize', 'productQty', 'Qty']

    useEffect(() => {
        if (wearhouse) {
            fetchList()
        }
    }, [wearhouse, search])

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLWEARHOUSEPRODUCTPAGING",
            "data": { "WearHouse_ID": wearhouse, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10, "Filter": search ? search : null },
            "debug": null,
            "token": null
        }
        dispatch(getAllWarehouseProductPaging(data)).then(res => {
            setIsLoading(false)
        })
    }
    useEffect(() => {
        console.log(warehouseProductPaging)
        let arr = []
        if (wearhouse) {
            warehouseProductPaging.forEach((ele) => {
                const obj = { ...ele }
                obj['vendorValue'] = filterName(vendorList, obj.vendor_ID);
                obj['Qty'] = '';
                arr.push(obj)
            })
        }
        setProducts(arr)
        setProductsList(arr)

    }, [warehouseProductPaging, wearhouse])


    const submit = () => {
        if (orderList.length > 0) {
            setIsSaveLoading(true)
            console.log(orderList)
            let data = {
                "sid": null,
                "lid": null,
                "reqInfo": null,
                "msgType": "INSERTRFTSREALTEDRECORD",
                "data": orderList,
                "debug": null,
                "token": null
            }
            dispatch(createPo(data)).then(res => {
                setIsSaveLoading(false)
                navigation(-1)
            })
        }
    }

    const addOrderList = (obj, index) => {
        console.log(obj)
        let arr = [...orderList]
        obj.map(ele => {
            if (ele.Qty != '') {
                arr.push({ "Qty": ele.Qty, "PackSize": ele.packSize_Detail, "Product_ID": ele.product_ID, "ProductDetail_ID": ele.productDetail_ID, "CreateBy": user?.userId, "FromLocationID": wearhouse, "FromLocationTypeID": 1, "ToLocationID": user.locationID, "ToLocationTypeID": 2, "RFTSDate": moment(new Date()).format("YYYY-MM-DD") })
                setOrderList(arr)
            }
        })
    }

    const onRemove = (data, index) => {
        const obj = {
            ...data,
            Qty: ""
        }
        let arr = [...Products]
        arr.unshift(obj)
        setProducts(arr)
        orderList.splice(index, 1)
        setOrderList(orderList)
    }

    const TableAction = ({ data }) => {
        const [open, setOpen] = React.useState(false);
        return (
            <>
                <ModalButton open={open} setOpen={setOpen} tooltip={'Request products'} img={add} type={'icon'}>
                    <FillRequestData data={data.id} addOrderList={addOrderList} />
                </ModalButton>
            </>
        )
    }

    return (
        <Card style={{ height: '100vh', margin: '15px', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >RFTS</Typography>
                <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Request'} />
            </Box>
            <Grid container >
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <SearchBar placeholder={'Search...'} handleChange={setSearch} />
                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>

                </Grid>
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                    <Autocomplete
                        disablePortal
                        options={wearhouseList}
                        sx={{ width: '80%' }}
                        size="small"
                        getOptionLabel={(option) => {
                            return wearhouseList.find(e => (e.name == option.name))?.name || ""
                        }}
                        onChange={(_, data) => setWearhouse(data?.id)}
                        renderInput={(params) => <TextField {...params} label="Wearhouses" />}
                    />
                </Grid>
            </Grid>


            {orderList.length > 0 && <SelectedProduct
                title={"Selected:"}
                selectedHeader={selectedHeader}
                selectedParam={selectedParam}
                list={orderList.map(e => ({
                    ...ProductsList.filter(val => val.product_ID == e.Product_ID)[0],
                    Qty: e?.Qty,
                    packSizeValue: filterName(packSizeList, e.PackSize_ID)
                }))
                }
                onRemove={onRemove}
            />}
            <Card style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    count={wearhouse ? wearhouseProductPagingcount : 0}
                    fetchList={fetchList}
                    action={(data) => <TableAction data={data} />}
                    actionText={"Request"} />
            </Card>
        </Card>
    );
};

export default RFTS;
