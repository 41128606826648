import React, { useEffect, useState } from "react";
import { Box, Typography, Grid } from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import details from '../../assets/details.png'
import SearchBar from "../../components/searchBar/searchBar";
import { filterName } from "../../utils/Utils";
import { getAllWarehouseProductPaging } from "../../store/actions/warehouse";
import ModalButton from "../../components/modalButton/modalButton";
import WearhouseProductDetail from "./wearhouseProductsDetail";

const WearhouseProducts = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const { warehouseProductPaging, wearhouseProductPagingcount } = useSelector(state => state.warehouse)

    const { vendorList } = useSelector(state => state.filler)
    const [Products, setProducts] = useState([])
    const location = useLocation();
    const { state } = location

    const [search, setSearch] = useState('')

    const tableHeader = ['DIN Number', 'Vendor', 'Product Name', 'Strenght', 'Quantity']
    const verifyParam = ['diN_Master', 'vendorValue', 'productName', 'mg', 'productQty']

    useEffect(() => {
        console.log(state)
        fetchList()
    }, [search])

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLWEARHOUSEPRODUCTPAGING",
            "data": { "WearHouse_ID": state.id, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10, "Filter": search ? search : null },
            "debug": null,
            "token": null
        }
        dispatch(getAllWarehouseProductPaging(data)).then(res => {
            setIsLoading(false)
        })
    }


    useEffect(() => {
        console.log(warehouseProductPaging)
        let arr = []
        warehouseProductPaging.forEach((ele) => {
            const obj = { ...ele }
            obj['vendorValue'] = filterName(vendorList, obj.vendor_ID);
            arr.push(obj)
        })
        setProducts(arr)

    }, [warehouseProductPaging])


    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Wearhouse Products</Typography>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Grid container >
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchBar placeholder={'Search...'} handleChange={setSearch} />
                    </Grid>
                </Grid>
                <TableContainer tableHeader={tableHeader}
                    data={Products}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    count={wearhouseProductPagingcount}
                    fetchList={fetchList}
                    action={TableAction}
                />
            </Card>
        </>
    );
};

export default WearhouseProducts;


const TableAction = ({ id }) => {
    const [open, setOpen] = React.useState(false);

    return (
        <ModalButton open={open} setOpen={setOpen} tooltip={'Details'} img={details} type={'icon'}>
            <WearhouseProductDetail setOpen={setOpen} state={id} />
        </ModalButton>
    )
}