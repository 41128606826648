import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPharmacy } from "../../store/actions/pharmacy";
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import PharmacyForm from "./pharmacyForm";
import edit from '../../assets/edit.png'

const Pharmacy = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { pharmacy } = useSelector(state => state.pharmacy)
    const { user } = useSelector(state => state.auth)
    const [Pharmacies, setPharmacies] = useState([])
    const [open, setOpen] = React.useState(false);

    const tableHeader = ['Pharmacy Name', 'Email', 'Website', 'Phone']
    const verifyParam = ['pharmacyName', 'emailAddress', 'website', 'phone']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPHARMACY",
            "data": { "Company_ID": user?.businessId },
            "debug": null,
            "token": null
        }
        dispatch(getAllPharmacy(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        console.log(pharmacy)
        pharmacy.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setPharmacies(arr)

    }, [pharmacy])

    const hasPermission = useHasPermission(['Pharmacy', 'edit'])
    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Pharmacy</Typography>
                <PermissionComp list={['Pharmacy', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Pharmacy'} type={'Button'}>
                        <PharmacyForm setOpen={setOpen}/>
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Pharmacies}
                    verifyParam={verifyParam}
                    isLoading={isLoading} 
                    action={hasPermission ? TableAction : null}/>
            </Card>
        </>
    );
};

export default Pharmacy;



const TableAction = ({ id }) => {
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <PermissionComp list={['Pharmacy', 'edit']}>
            <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit Pharmacy'} img={edit} type={'icon'}>
                <PharmacyForm data={id}  setOpen={setOpenEdit} />
            </ModalButton>
        </PermissionComp>
    )
}