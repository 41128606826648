import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { getAllCompany } from "../../store/actions/company";
import { useDispatch, useSelector } from "react-redux";
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import CompanyForm from "./companyForm";
import edit from '../../assets/edit.png'
const Company = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [companies, setCompanies] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { company } = useSelector(state => state.company)
    const [open, setOpen] = React.useState(false);

    const tableHeader = ['Company Name', 'Email', 'Website', 'Phone', 'Status']
    const verifyParam = ['companyName', 'emailAddress', 'website', 'phone', 'isActive']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLCOMPANY",
            "data": {},
            "debug": null,
            "token": null
        }
        dispatch(getAllCompany(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        company.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setCompanies(arr)

    }, [company])

    const hasPermission = useHasPermission(['Company', 'edit'])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Companies</Typography>
                <PermissionComp list={['Company', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Company'} type={'Button'}>
                        <CompanyForm setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer
                    tableHeader={tableHeader}
                    data={companies}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={hasPermission ? TableAction : null} />
            </Card>
        </>
    );
};

export default Company;


const TableAction = ({ id }) => {
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <PermissionComp list={['Company', 'edit']}>
            <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit Company'} img={edit} type={'icon'}>
                <CompanyForm data={id}  setOpen={setOpenEdit} />
            </ModalButton>
        </PermissionComp>
    )
}
