import React, { useContext } from "react";
import "./headingTitle.css";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { IoMdClose } from "react-icons/io";
const HeadingTitle = (props) => {
  const { title, handleClose } = props

  return <Box sx={{ width: '100%' }}>
    <Box className="titleContainer">
      <Typography variant="h3" className="headingTitle">
        {title}
      </Typography>
      <IconButton className='icon' onClick={handleClose} size="small">
        <IoMdClose color="rgb(94, 53, 177)" size={25} />
      </IconButton>
    </Box>
    <Divider sx={{ margin: '10px 0px' }} />
  </Box>
};

export default HeadingTitle;
