import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton } from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllWarehouse } from "../../store/actions/warehouse";
import PermissionComp from "../../components/permissionComp/permissionComp";
import details from '../../assets/details.png'
import ModalButton from "../../components/modalButton/modalButton";
import WareHouseForm from "./warehouseForm";
import edit from '../../assets/edit.png'

const WareHouse = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { warehouse } = useSelector(state => state.warehouse)
    const { user } = useSelector(state => state.auth)
    const [warehouses, setWarehouses] = useState([])
    const [open, setOpen] = React.useState(false);

    const tableHeader = ['WareHouse Name', 'Email', 'Website', 'Phone']
    const verifyParam = ['wearHouseName', 'emailAddress', 'website', 'phone']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLWEARHOUSE",
            "data": { "Company_ID": user?.businessId },
            "debug": null,
            "token": null
        }
        dispatch(getAllWarehouse(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {
        let arr = []
        warehouse.forEach((ele) => {
            const obj = { ...ele }
            // obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setWarehouses(arr)

    }, [warehouse])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Warehouse</Typography>
                <PermissionComp list={['Wearhouse', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Wearhouse'} type={'Button'}>
                        <WareHouseForm setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <TableContainer tableHeader={tableHeader}
                    data={warehouses}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={TableAction} />
            </Card>
        </>
    );
};

export default WareHouse;




const TableAction = ({ id }) => {
    const navigation = useNavigate()
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <Box>
            <PermissionComp list={['Wearhouse', 'edit']}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit Wearhouse'} img={edit} type={'icon'}>
                    <WareHouseForm data={id} setOpen={setOpenEdit} />
                </ModalButton>
            </PermissionComp>
            <Tooltip title="Wearhouse Products">
                <IconButton onClick={() => navigation('wearhouseProducts', { state: id })} sx={{ width: '40px', margin: '0px auto' }}>
                    <img src={details} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}