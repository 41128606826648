import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllPharmacy } from "../../store/actions/pharmacy";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { getAllVendor } from "../../store/actions/vendor";
import ModalButton from "../../components/modalButton/modalButton";
import VendorForm from "./vendorForm";

const Vendor = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { vendor } = useSelector(state => state.vendor)
    const { user } = useSelector(state => state.auth)
    const [Vendors, setVendors] = useState([])
    const [open, setOpen] = React.useState(false);

    const tableHeader = ['Vendor Name', 'Abbrevation', 'Email', 'Phone', 'Company']
    const verifyParam = ['vendorName', 'abbrevation', 'emailAddress', 'phone', 'companyName']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLVENDOR",
            "data": { "Company_ID": user?.businessId },
            "debug": null,
            "token": null
        }
        dispatch(getAllVendor(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        console.log(vendor)
        vendor.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            arr.push(obj)
        })
        setVendors(arr)

    }, [vendor])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Vendor</Typography>
                <PermissionComp list={['Vendor', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Vendor'} type={'Button'}>
                        <VendorForm setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Vendors}
                    verifyParam={verifyParam}
                    isLoading={isLoading} />
            </Card>
        </>
    );
};

export default Vendor;
