import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
// import Header from '../header/header';
import { Outlet } from 'react-router-dom';
import Sidebar from '../sidebar/sidebar';
import './mainlayout.scss'
import Header from '../header/header';


const MainLayout = () => {
    const [sideMenuToggle, setSideMenuToggle] = useState(false);

    const sideMenuToggleHandler = () => {
        setSideMenuToggle((prev) => !prev);
    }

    return (
        <Box sx={{ height: '100vh', display: 'flex' }}>
            {/* <Box className='mainLayoutContainer'></Box> */}
            <Box className="sidebar" sx={{ width: sideMenuToggle ? '5%' : '15%' }}>
                <Sidebar sideMenuToggleHandler={sideMenuToggleHandler} sideMenuToggle={sideMenuToggle} />
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Header />
                <Outlet />
            </Box>
        </Box>
    );
};


export default MainLayout;