import React, { useEffect, useState } from 'react';
import { Tooltip, Box, Typography, IconButton } from '@mui/material';
import './sidebar.scss'
import { IoIosArrowBack } from "react-icons/io";
import { navData } from './sidemenuNav';
import { useLocation, useNavigate } from "react-router-dom";
import userProfile from '../../assets/userProfile.png'
import { ProfilePhoto } from '../../components/profilePhoto/profilePhoto';
import { useSelector } from 'react-redux';
import { filterName } from '../../utils/Utils';
import { userRole } from '../../utils/lists';

const Sidebar = (props) => {
    const { sideMenuToggleHandler, sideMenuToggle } = props
    const navigate = useNavigate()
    const [activeItem, setActiveItem] = useState('')
    const location = useLocation()

    const { user, permission } = useSelector(state => state.auth)
    const [navList, setNavList] = useState([])

    useEffect(() => {

        const pathname = location.pathname.split('/')[1]
        setActiveItem(pathname.charAt(0).toUpperCase() + pathname.slice(1))
    }, [])

    useEffect(() => {
        console.log(user.userType)
        if (user.userType != 1) {
            setNavList(navData.filter(item => item.label !== 'Companies'))
        } else {
            setNavList(navData)
        }
    }, [user])


    const onMenuSelect = (e) => {
        console.log(e, navData)
        setActiveItem(e.label)
        navigate(e.url);
    }

    console.log(permission)

    return (
        <Box className={`sidemenuContainer ${sideMenuToggle ? 'collapsed' : ''}`}>
            <IconButton className='sideMenuToggle' onClick={() => {
                sideMenuToggleHandler()
            }} size="small">
                <IoIosArrowBack className={`icon ${sideMenuToggle ? 'rotated' : ''}`} />
            </IconButton>

            <Box className='logoContainer'>
                <Typography variant='h1' color={'#fff'} >IMS</Typography>
            </Box>

            <Box className='userProfileContainer'>
                {/* <img src={userProfile} /> */}

                <ProfilePhoto image={userProfile} classname='userPhotoContainer' />
                {!sideMenuToggle && <>
                    <Typography variant='h3' color={'#fff'} >{user.userInfo}</Typography>
                    <Typography variant='body1' color={'#fff'} >{filterName(userRole, user.userType)}</Typography>
                </>}
            </Box>
            <Box sx={{ padding: '20px' }}>
                {
                    navList.map((e, i) => {
                        return (
                            permission[e.label]?.isEnable && (<Box key={i} className={`menuItem ${activeItem == e.label ? 'selectedMenu' : ''}`} onClick={() => onMenuSelect(e)}>
                                {
                                    sideMenuToggle ? <Tooltip title={e.label}>
                                        {e.icon}
                                    </Tooltip> :
                                        e.icon
                                }
                                {
                                    !sideMenuToggle ?
                                        <Typography variant='h3' color={'#fff'} marginLeft={'20px'} >{e.label}</Typography>
                                        : null
                                }
                                {e.label == 'Requests' && <Box sx={{ height: '10px', width: '10px', background: '#9f88ba', borderRadius: '100px', margin: '0px auto' }}></Box>}
                            </Box>
                            )
                        )
                    })
                }
            </Box>
        </Box>
    );
};


export default Sidebar;