import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import Expandable from "./expandable/expandable";
import Delete from '../assets/delete.png'

const SelectedProduct = (props) => {
    const { title, selectedHeader, selectedParam, list, onRemove } = props
    console.log(list)
    return (
        <Expandable title={title}>
            <Grid container spacing={1} sx={{ padding: '10px 20px' }}>
                {selectedHeader.map(e => <Grid xs={11 / selectedHeader.length}>
                    <Typography>{e}</Typography>
                </Grid>)}
                <Grid xs={1}>
                </Grid>
            </Grid>
            <Divider sx={{ margin: '10px 0px' }} />
            <Box sx={{ margin: '15px 20px' }}>
                <Grid container spacing={1}>

                    {
                        list.map((e, i) => {

                            return (
                                <>
                                    {selectedParam.map(p => <Grid xs={11 / selectedHeader.length} sx={{ margin: '10px 0px' }}>
                                        <Typography>{e[p]}</Typography>
                                    </Grid>)}
                                    <Grid xs={1} sx={{ margin: '10px 0px' }}>
                                        <IconButton className='icon' size="small" onClick={() => onRemove(e, i)}>
                                            <img src={Delete} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                                        </IconButton>
                                    </Grid>
                                </>
                            )
                        })
                    }
                </Grid>
            </Box>
        </Expandable>
    );

};

export default SelectedProduct;
