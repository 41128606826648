import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { getAllCompany } from "../../store/actions/company";
import { useDispatch, useSelector } from "react-redux";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { getAllRFTSByPharmacy } from "../../store/actions/rfts";
import details from '../../assets/details.png'
import moment from "moment";

const RequestReceived = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector(state => state.auth)

    const tableHeader = ['Pharmacy Name', 'Request Date', 'Status']
    const verifyParam = ['pharmacyName', 'requestDate', 'statusName']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYWEARHOUSEANDPHARMACY",
            "data": { "WearHouse_ID": user.locationID },
            "debug": null,
            "token": null
        }
        dispatch(getAllRFTSByPharmacy(data)).then(res => {
            console.log(res)
            setRequests(res.payload.data.map(ele => ({ ...ele, requestDate: (moment(ele.rftsDate).format("YYYY-MM-DD")) })))
            setIsLoading(false)
        })
    }, [])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Requests</Typography>
            </Box>
            <Card style={{ flex: 1 ,overflow: 'auto'}}>
                <TableContainer
                    tableHeader={tableHeader}
                    data={requests}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={TableAction}  />
            </Card>
        </>
    );
};

export default RequestReceived;



const TableAction = ({ id }) => {
    const navigation = useNavigate()

    return (
        <Box>
            <Tooltip title="Request Received">
                <IconButton onClick={() => navigation('requestReceived', { state: id })} sx={{ width: '40px', margin: '0px auto' }}>
                    <img src={details} style={{ width: '25px', height: '25px', objectFit: 'contain' }} />
                </IconButton>
            </Tooltip>
        </Box>
    )
}