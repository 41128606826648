import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

const PermissionComp = (props) => {
    const { children, list } = props

    const { permission } = useSelector(state => state.auth)

    const findPermission = () => {
        let foundPermission = null;
        list?.map((e, i) => {
            if (i == 0) {
                foundPermission = permission[e]
            } else {
                foundPermission = foundPermission[e]
            }
        })
        return foundPermission?.isEnable;
    }

    return (
        <>
            {findPermission() &&
                <>
                    {children}
                </>
            }
        </>
    );
};


export default PermissionComp;

export const useHasPermission = (list) => {
    const { permission } = useSelector(state => state.auth);

    const findPermission = () => {
        let foundPermission = null;
        list?.map((e, i) => {
            if (i == 0) {
                foundPermission = permission[e]
            } else {
                foundPermission = foundPermission[e]
            }
        })
        return foundPermission?.isEnable;
    }

    return findPermission();
};
