import { createSlice } from "@reduxjs/toolkit";
import {
  login,
} from "../actions/auth";
import { permission } from "../../utils/permission";

const initialState = {
  isLoggedIn: false,
  user: null,
  permission: null
}

const authSlice = createSlice({
  name: "auth",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload
      state.permission = permission.filter(val => val.role == action.payload.userType)[0]
      localStorage.setItem('IMS', JSON.stringify({
        user: action.payload,
        permission: permission.filter(val => val.role == action.payload.userType)[0]
      }));
    });
  },
  reducers: {
    saveUserData: (state, action) => {
      state.user = action.payload.user;
      state.permission = action.payload.permission;
      state.isLoggedIn = true;
    },
    logoutUser: (state, action) => {
      state.user = null;
      state.permission = null;
      state.isLoggedIn = false;
      localStorage.removeItem('IMS')
    },
  }
});
export default authSlice.reducer;

export const { saveUserData, logoutUser } = authSlice.actions;