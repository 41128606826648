import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button, Grid } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProduct } from "../../store/actions/product";
import details from '../../assets/details.png'
import edit from '../../assets/edit.png'
import SearchBar from "../../components/searchBar/searchBar";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { filterName } from "../../utils/Utils";
import ModalButton from "../../components/modalButton/modalButton";
import ProductForm from "./productForm";
import ProductDetail from "./productDetail";
import PackSizeForm from "./packSizeForm";
import ButtonContainer from "../../components/buttonContainer";

const PackSize = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { packSizeList } = useSelector(state => state.filler)
    const [openPackSize, setOpenPackSize] = React.useState(false)



    const tableHeader = ['Name']
    const verifyParam = ['name']

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Products</Typography>
                <ButtonContainer isSingle>
                    <PermissionComp list={['Products', 'packSize', 'create']}>
                        <ModalButton open={openPackSize} setOpen={setOpenPackSize} tooltip={'Create PackSize'} type={'Button'}>
                            <PackSizeForm setOpen={setOpenPackSize} />
                        </ModalButton>
                    </PermissionComp>
                </ButtonContainer>
            </Box>
            <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <TableContainer tableHeader={tableHeader}
                    data={packSizeList}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                />
            </Card>
        </>
    );
};

export default PackSize;
