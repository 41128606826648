import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import './po.scss'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPo } from "../../store/actions/po";
import { filterName, setDataObject } from "../../utils/Utils";
import { getAllProduct } from "../../store/actions/product";
import add from '../../assets/add.png';
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import FilterBar from "../../components/filterBar/filterBar";
import SaveButton from "../../components/saveButton/saveButton";

const PoDetailForm = (props) => {
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { user } = useSelector(state => state.auth)
    const { poID, vendorID } = props
    const { packSizeList } = useSelector(state => state.filler)

    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const { product } = useSelector(state => state.product)

    const [Products, setProducts] = useState([])
    const [ProductsList, setProductsList] = useState([])
    const [orderList, setOrderList] = useState([])
    const [search, setSearch] = useState('')
    const [count, setCount] = useState(0)

    const tableHeader = ['DIN Number', 'Product Name', 'Strenght']
    const verifyParam = ['diN_Master', 'productName', 'mg']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Pack Size', 'Quantity Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'packSizeValue', 'Qty']

    useEffect(() => {
        fetchList()
    }, [])

    const fetchList = (obj, rowsPerPage, page) => {
        setIsLoading(true)
        setProducts([])
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCT",
            "data": { "CompanyID": user?.businessId, "Vendor_ID": vendorID, 'PageIndex': page ? page + 1 : 1, "PageSize": rowsPerPage ? rowsPerPage : 10 },
            "debug": null,
            "token": null
        }
        dispatch(getAllProduct(data)).then(res => {
            setCount(res.payload.total)
            let list = res.payload.list.map(ele => (
                { ...ele, Qty: "", PackSize_ID: "" }
            ));
            setProducts(list)
            setProductsList(list)

            setIsLoading(false)
        })
    }

    const submit = () => {
        setIsSaveLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTPODETAIL",
            "data": orderList,
            "debug": null,
            "token": null
        }
        console.log(data)
        dispatch(createPo(data)).then(res => {
            setIsSaveLoading(false)
        })
    }

    const handleChange = (value, paramName, data) => {
        const arr = [...Products]
        arr[data.index][paramName] = value
    }


    const TableAction = ({ data }) => {
        console.log(data)
        return (
            <>
                <TextField
                    size="small"
                    onChange={(e) => handleChange(e.target.value, 'Qty', data)}
                    type="text"
                    label="Quantity"
                />
                <IconButton className='icon' size="small" onClick={() => addInventory(data.id, data.index)}>
                    <img src={add} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                </IconButton>
            </>
        )
    }
    const addInventory = (obj, index) => {
        console.log(obj, index)
        let arr = [...orderList]
        arr.push(setDataObject({ "PoId": String(poID), "ProductId": obj.id, "Qty": obj.Qty, "PackSize_ID": obj.PackSize_ID, "CreatedBy": user?.userId }))
        setOrderList(arr)
        Products.splice(index, 1)
        setProducts(Products)
    }

    const onRemove = (data, index) => {
        const obj = {
            ...data,
            Qty: "",
            PackSize_ID: ""
        }
        let arr = [...Products]
        arr.unshift(obj)
        setProducts(arr)
        orderList.splice(index, 1)
        setOrderList(orderList)
    }

    const TableExtra = (data) => {
        return (
            <Autocomplete
                disablePortal
                options={packSizeList}
                sx={{ width: '100%' }}
                size="small"
                getOptionLabel={(option) => {
                    return packSizeList.find(e => (e.name == option.name))?.name || ""
                }}
                onChange={(_, val) => handleChange(val.id, 'PackSize_ID', data)}
                renderInput={(params) => <TextField {...params} label="Pack Size" />}
            />
        )
    }

    return <Card style={{ flex: 1, flexDirection: 'column' }}>
        <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='h1' >Purchase Order Detail</Typography>
            {/* <Button size="medium" variant="contained" color="primary" onClick={submit} >Save</Button> */}
            <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Save'} />
        </Box>
        {orderList.length > 0 && <SelectedProduct
            title={"Selected:"}
            selectedHeader={selectedHeader}
            selectedParam={selectedParam}
            list={orderList.map(e => {
                console.log(e)
                return ({
                    ...product.find(val => val.id == e.ProductId),
                    Qty: e?.Qty,
                    PackSize_ID: e.PackSize_ID,
                    packSizeValue: filterName(packSizeList, e.PackSize_ID)
                })
            })
            }
            onRemove={onRemove}
        />}
        <Grid container >
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                <FilterBar placeholder={'Search...'} handleChange={setSearch} />
            </Grid>
        </Grid>
        <Card style={{ flex: 1, display: 'flex', overflowX: 'auto', flexDirection: 'column' }}>
            <TableContainer tableHeader={tableHeader}
                data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                verifyParam={verifyParam}
                isLoading={isLoading}
                count={count}
                fetchList={fetchList}
                extraColumnFeilds={["Pack Size"]}
                extraColumnParams={['PackSize']}
                extraColumn={[TableExtra]}
                action={(data) => <TableAction data={data} />}
                actionText={"Quantity Received"} />
        </Card>
    </Card>
};

export default PoDetailForm;
