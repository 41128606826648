import React from "react";
import "./expandable.scss";
import { Box, Collapse, Divider, Typography } from "@mui/material";
import { MdExpandMore, MdOutlineExpandLess } from "react-icons/md";
import { useTheme } from '@mui/material/styles';

const Expandable = (props) => {
  const { children, title, optionLabel ,style } = props

  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(prev => !prev);
  };
  return <Box sx={{margin:'10px'}}>
    <Box onClick={handleExpandClick} className="searchBar" sx={style}  >
      <Typography variant="h4" component="span" className="collapseableContainer">
        {title}
      </Typography>
      {optionLabel ? <Typography variant="body1" component="span" className="optionLabel">
        {optionLabel}
      </Typography> : <>
        {
          expanded ? <MdOutlineExpandLess size={25} color={theme.palette.textColor} />
            : <MdExpandMore size={25} color={theme.palette.textColor} />}
      </>}
    </Box >
    <Collapse
      in={expanded}
      timeout="auto"
      unmountOnExit
    >
      <Box className='childContainer'>
        {children} 
        </Box>
    </Collapse>
    {/* {expanded && <Divider variant="middle" sx={{ margin: '20px 0px' }} />} */}
  </Box>
};

export default Expandable;
