import { createSlice } from "@reduxjs/toolkit";
import { getCountries } from "../actions/address";

const initialState = {
  countries: [],
  totalcount: 0,
}

const addressSlice = createSlice({
  name: "address",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getCountries.pending, (state, action) => {
      state.countries = []
    });
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
    });
  }
});
export default addressSlice.reducer;
