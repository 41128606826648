import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { createInventory, getAllPoDetail } from "../../store/actions/po";
import { useForm, useFieldArray } from "react-hook-form";
import InputFields from "../../components/InputFields";
import DatePickerFields from "../../components/datePickerField";
import add from '../../assets/add.png';
import { useLocation, useNavigate } from "react-router-dom";
import { filterName, setDataObject } from "../../utils/Utils";
import { getAllProduct } from "../../store/actions/product";
import TableContainer from "../../components/table/table";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import SelectedProduct from "../../components/selected";
import FilterBar from "../../components/filterBar/filterBar";
import moment from "moment";
import SaveButton from "../../components/saveButton/saveButton";

const QtyReceiveForm = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const navigation = useNavigate()
    const { packSizeList } = useSelector(state => state.filler)

    const theme = useTheme()

    const location = useLocation();
    const { state } = location
    const { podetail, item, vendorID } = state;
    const [Products, setProducts] = useState([])
    const [inventoryList, setInventoryList] = useState([])
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const tableHeader = ['DIN Number', 'Product Name', 'Strenght', 'Required Quantity', 'Remaining Quantity']
    const verifyParam = ['diN_Master', 'productName', 'mg', 'requiredQty', 'remainingQty']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Pack Size', 'Expiry', 'Quantity Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'packSizeValue', 'ExpiryTo', 'QtyRecived']

    useEffect(() => {
        getPODetail()
    }, [])

    // const getProducts = async () => {
    //     setIsLoading(true)
    //     let data = {
    //         "sid": null,
    //         "lid": null,
    //         "reqInfo": null,
    //         "msgType": "GETALLPRODUCT",
    //         "data": { "CompanyID": user?.businessId, "Vendor_ID": vendorID },
    //         "debug": null,
    //         "token": null
    //     }
    //     await dispatch(getAllProduct(data)).then(async res => {

    //         await getPODetail()
    //     })
    // }

    const getPODetail = () => {
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPODETAIL",
            "data": { "PO_ID": podetail?.pO_ID },
            "debug": null,
            "token": null
        };

        dispatch(getAllPoDetail(data)).then(res => {

            setIsLoading(false)
            console.log(res.payload.data)
            const poProducts = res.payload.data.map(ele => (
                { ...ele, requiredQty: ele.qty, remainingQty: ele.remainingQty ? ele.remainingQty : ele.qty, ExpiryTo: '', PackSize_ID: '' }
            ));
            setProducts(poProducts)
        });
    }


    const submit = () => {
        setIsSaveLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTINVENTORYREALTEDRECORD",
            "data": inventoryList,
            "debug": null,
            "token": null
        }
        console.log(data)
        dispatch(createInventory(data)).then(res => {
            console.log(res)
            setIsSaveLoading(false)
            navigation(-1)
        })
    }

    const addInventory = (obj) => {
        const { id, index } = obj
        console.log(id, index, Products)

        let arr = [...inventoryList]
        arr.push(setDataObject({
            "Grnid": item?.id,
            "QtyRecived": id.receivedQty,
            "ExpiryTo": id.ExpiryTo,
            "Product_Id": id?.product_ID,
            "ProductName": id?.productName,
            "Company_Id": user?.businessId,
            "CreatedBy": user?.userId,
            "PackSize_ID": id.PackSize_ID
        }))
        setInventoryList(arr)
        let productArr = [...Products]
        productArr[index]['receivedQty'] = ""
        productArr[index]['ExpiryTo'] = ""
        productArr[index].PackSize_ID = ""
        setProducts(productArr)

    }

    const handleChange = (qty, paramName, data) => {
        console.log(qty, paramName, data)
        let arr = [...Products]
        arr[data.index][paramName] = qty
        if (paramName == 'receivedQty') {
            arr[data.index]['remainingQty'] = parseInt(arr[data.index]['remainingQty']) - parseInt(qty)
        }
    }

    const TableAction = ({ data }) => {
        const [localInputValue, setLocalInputValue] = useState(Products[data.index]?.receivedQty || '');

        const handleInputChange = (e) => {
            const value = e.target.value;
            console.log(value)
            if (value <= Products[data.index]?.remainingQty) {

                let arr = [...Products]
                arr[data.index].receivedQty = value
                setLocalInputValue(value);
            }
        };
        const handleAddInventory = () => {
            handleChange(localInputValue, 'receivedQty', data)
            addInventory(data); // Call the addInventory function
            setLocalInputValue(''); // Clear the input field
        };
        return (
            <>
                <TextField
                    size="small"
                    value={localInputValue}
                    onChange={handleInputChange}
                    type="number"
                    label="Quantity"
                />
                <IconButton className='icon' size="small" onClick={handleAddInventory}>
                    <img src={add} style={{ width: '20px', height: '20px', objectFit: 'contain' }} />
                </IconButton>
            </>
        )
    }

    const DateComp = (data) => {
        console.log(Products[data.index]?.ExpiryTo ? true : false, data.index)

        return (
            <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        slotProps={{
                            layout: { sx: { bgcolor: theme.palette.headerBackgroundColor } },
                            leftArrowIcon: { sx: { color: theme.palette.textColor } },
                            rightArrowIcon: { sx: { color: theme.palette.textColor } },
                            switchViewIcon: { sx: { color: theme.palette.textColor } },
                            openPickerIcon: { sx: { color: theme.palette.textColor } },
                            textField: { size: 'small' }
                        }}
                        className="outlined"
                        label={`Expiry`}
                        value={Products[data.index]?.ExpiryTo ? dayjs(Products[data.index]?.ExpiryTo) : null}
                        onChange={(e) => handleChange(moment(String(e)).format('YYYY-MM-DD'), 'ExpiryTo', data)}
                        sx={{ width: '100%' }}
                    />
                </LocalizationProvider>
            </Box>
        )
    }
    const PackSizeComp = (data) => {
        console.log(data, Products)
        // const [localInputValue, setLocalInputValue] = useState(Products[data.index]?.PackSize_ID || '');

        const handleInputChange = (value) => {
            // console.log(value, localInputValue)
            let arr = [...Products]
            arr[data.index].PackSize_ID = value
            // setLocalInputValue(value);
            handleChange(value, 'PackSize_ID', data)
        };
        return (
            <Autocomplete
                disablePortal
                options={packSizeList}
                sx={{ width: '100%' }}
                size="small"
                getOptionLabel={(option) => {
                    return packSizeList.find(e => (e.name == option.name))?.name || ""
                }}
                onChange={(_, val) => handleInputChange(val?.id)}
                renderInput={(params) => <TextField {...params} sx={{ width: '100%' }} label="Pack Size" />}
            />
        )
    }

    const onRemove = (data, index) => {
        console.log(Products, data, index, Products.findIndex(val => val.id == data.id))
        const getIndexOfProduct = Products.findIndex(val => val.product_ID == data.product_ID)
        let arr = [...Products]
        arr[getIndexOfProduct]['remainingQty'] = parseInt(arr[getIndexOfProduct]['remainingQty']) + parseInt(data.QtyRecived)
        setProducts(arr)
        inventoryList.splice(index, 1)
        setInventoryList(inventoryList)
    }

    return (
        <Card style={{ flex: 1 }}>
            <Box className="poFormHeader">
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h2'>Quantity Received</Typography>
                    <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Received'} />
                </Box>
                <Divider sx={{ margin: '10px 0px' }} />
            </Box>
            {inventoryList.length > 0 && <SelectedProduct
                title={"Selected:"}
                selectedHeader={selectedHeader}
                selectedParam={selectedParam}
                list={inventoryList.map(e => {
                    console.log(e, Products)
                    return ({
                        ...Products.find(val => val.product_ID == e.Product_Id),
                        QtyRecived: e?.QtyRecived,
                        ExpiryTo: e?.ExpiryTo,
                        packSizeValue: filterName(packSizeList, e.PackSize_ID)

                    })
                })
                }
                onRemove={onRemove}
            />}
            <Grid container >
                <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <FilterBar placeholder={'Search...'} handleChange={setSearch} />
                </Grid>
            </Grid>
            <Card style={{ height: '80%', display: 'flex', overflowX: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    extraColumnFeilds={["Expiry", 'Pack Size']}
                    extraColumnParams={['ExpiryTo', 'PackSize']} //Pass the parameters in this array from which you want to get the value.
                    extraColumn={[DateComp, PackSizeComp]}
                    action={(data) => <TableAction data={data} />}
                    actionText={"Quantity Received"} />
            </Card>
        </Card>
    );
};

export default QtyReceiveForm;


