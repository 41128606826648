import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { getAllCompany } from "../../store/actions/company";
import { useDispatch, useSelector } from "react-redux";
import PermissionComp from "../../components/permissionComp/permissionComp";
import { getAllRFTSByPharmacy } from "../../store/actions/rfts";
import moment from "moment";
const RFTSList = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const [requests, setRequests] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useSelector(state => state.auth)

    const tableHeader = ['Wearhouse Name', 'Request Date', 'Status']
    const verifyParam = ['wearHouseName', 'requestDate', 'statusName']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYWEARHOUSEANDPHARMACY",
            "data": { "Pharmacy_ID": user.locationID },
            "debug": null,
            "token": null
        }
        dispatch(getAllRFTSByPharmacy(data)).then(res => {
            console.log(res)
            setRequests(res.payload.data.map(ele => ({ ...ele, requestDate: (moment(ele.rftsDate).format("YYYY-MM-DD")) })))
            setIsLoading(false)
        })
    }, [])

    // useEffect(() => {

    //     let arr = []
    //     company.forEach((ele) => {
    //         const obj = { ...ele }
    //         obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
    //         arr.push(obj)
    //     })
    //     setCompanies(arr)

    // }, [company])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Requests</Typography>
                <PermissionComp list={['RFTS', 'create']}>
                    <ActionButton label={'Create Request'} onClick={() => navigation('create')} />
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1 }}>
                <TableContainer
                    tableHeader={tableHeader}
                    data={requests}
                    verifyParam={verifyParam}
                    isLoading={isLoading} />
            </Card>
        </>
    );
};

export default RFTSList;
