import { createSlice } from "@reduxjs/toolkit";
import { getAllPo, getAllPoDetail } from "../actions/po";

const initialState = {
  po: [],
  poDetail: [],
  totalcount: 0,
}

const poSlice = createSlice({
  name: "po",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllPo.pending, (state, action) => {
      state.po = []
    });
    builder.addCase(getAllPo.fulfilled, (state, action) => {
      console.log(action.payload.data)
      state.po = action.payload.data
      // state.totalcount = action.payload.total
    });
    builder.addCase(getAllPoDetail.pending, (state, action) => {
      state.poDetail = []
    });
    builder.addCase(getAllPoDetail.fulfilled, (state, action) => {
      state.poDetail = action.payload.data
      // state.totalcount = action.payload.total
    });
  },
  reducers: {
    createPORecord: (state, action) => {
      state.po.unshift(action.payload)
    },
  },
});
export default poSlice.reducer;
export const { createPORecord } = poSlice.actions;
