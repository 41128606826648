import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./reducers/ui-slice";
import authSlice from "./reducers/auth-slice";
import companySlice from "./reducers/company-slice";
import warehouseSlice from "./reducers/warehouse-slice";
import pharmacySlice from "./reducers/pharmacy-slice";
import userSlice from "./reducers/user-slice";
import productSlice from "./reducers/product-slice";
import poSlice from "./reducers/po-slice";
import grnSlice from "./reducers/grn-slice";
import addressSlice from "./reducers/address-slice";
import fillerSlice from "./reducers/filler-slice";
import vendorSlice from "./reducers/vendor-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice,
    auth: authSlice,
    company: companySlice,
    warehouse: warehouseSlice,
    pharmacy: pharmacySlice,
    user: userSlice,
    product: productSlice,
    po: poSlice,
    grn: grnSlice,
    address: addressSlice,
    filler: fillerSlice,
    vendor: vendorSlice,
  },
});

export default store;
