import { Box } from "@mui/material";
import React from "react";
const ButtonContainer = ({ children, isSingle }) => {

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
                justifyContent: 'end'
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: `repeat(${isSingle?1:2}, minmax(100px, 1fr))`,
                    gap: 2,
                }}
            >
                {children}
            </Box>
        </Box>
    );
};

export default ButtonContainer;
