import { createSlice } from "@reduxjs/toolkit";
import { getAllGrn } from "../actions/grn";

const initialState = {
  grn: [],
  totalcount: 0,
}

const grnSlice = createSlice({
  name: "grn",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllGrn.pending, (state, action) => {
      state.grn = []
    });
    builder.addCase(getAllGrn.fulfilled, (state, action) => {
      console.log(action.payload.data)
      state.grn = action.payload.data
      // state.totalcount = action.payload.total
    });
  }
});
export default grnSlice.reducer;
