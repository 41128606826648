import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import { createInventory } from "../../store/actions/po";
import add from '../../assets/add.png';
import { useLocation, useNavigate } from "react-router-dom";
import { filterName } from "../../utils/Utils";
import TableContainer from "../../components/table/table";
import SelectedProduct from "../../components/selected";
import { getAllRFTSDetail } from "../../store/actions/rfts";
import moment from "moment";
import ModalButton from "../../components/modalButton/modalButton";
import FillRequiredData from "./fillRequiredData";
import SearchBar from "../../components/searchBar/searchBar";
import SaveButton from "../../components/saveButton/saveButton";

const RequestReceiveForm = (props) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { packSizeList } = useSelector(state => state.filler)
    const navigation = useNavigate()

    const location = useLocation();
    const { state } = location
    const [Products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const [sentList, setSentList] = useState([])
    const [search, setSearch] = useState('')

    const tableHeader = ['DIN Number', 'Product Name', 'Strenght', 'Requested Quantity', 'Pack Size', 'Expiry']
    const verifyParam = ['diN_Master', 'productName', 'mg', 'requiredQuantity', 'packSizeValue', 'expiry']
    const selectedHeader = ['DIN Number', 'Product Name', 'Strenght', 'Expiry', 'sent Received']
    const selectedParam = ['diN_Master', 'productName', 'mg', 'expiry', 'sentQty']

    useEffect(() => {
        getRFTSDetail()
    }, [search])


    const getRFTSDetail = () => {
        setIsLoading(true)
        console.log(state)
        setProducts([])
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLRFTSBYRFTSID",
            "data": { "RFTS_ID": state?.rftsid, "Filter": search ? search : null },
            "debug": null,
            "token": null
        };

        dispatch(getAllRFTSDetail(data)).then(res => {
            setIsLoading(false)
            const result = res.payload.data.reduce((acc, current) => {
                const product = acc.find(p => p.product_ID === current.product_ID);
                if (product) {
                    product.records.push({ ...current, sentQty: "", expiry: moment(current.expiryTo).format('YYYY-MM-DD') });
                } else {
                    acc.push({
                        product_ID: current.product_ID,
                        productName: current.productName,
                        requiredQuantity: current.qty,
                        expiry: moment(current.expiryTo).format('YYYY-MM-DD'),
                        diN_Master: current.diN_Master,
                        mg: current.mg,
                        packSize_Master: current.packSize_Master,
                        packSizeValue: filterName(packSizeList, current.packSize_ID),
                        records: [{ ...current, sentQty: "", expiry: moment(current.expiryTo).format('YYYY-MM-DD') }]
                    });
                }
                return acc;
            }, []);
            setProducts(result)
        });
    }


    const submit = () => {
        setIsSaveLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTSTORELATEDRECORD",
            "data": sentList.map(e => ({ "RFTS_ID": state?.rftsid, "Qty": e.sentQty, "ProductDetail_ID": e.productDetail_ID, "CreateBy": user?.userId, "STODate": moment(new Date()).format("YYYY-MM-DD") })),
            "debug": null,
            "token": null
        }
        console.log(data)
        dispatch(createInventory(data)).then(res => {
            console.log(res)
            setIsSaveLoading(false)
            navigation(-1)
        })
    }

    const addOrderList = (list) => {
        let arr = [...sentList, ...list]
        setSentList(arr)
    }

    const TableAction = ({ data }) => {
        const [open, setOpen] = React.useState(false);
        return (
            <>
                <ModalButton open={open} setOpen={setOpen} tooltip={'Approve products'} img={add} type={'icon'}>
                    <FillRequiredData data={data.id} addOrderList={addOrderList} status={state.statusName} />
                </ModalButton>
            </>
        )
    }

    const onRemove = (data, index) => {
        let arr = [...sentList]
        arr.splice(index, 1)
        setSentList(arr)
    }

    return (
        <Card style={{ flex: 1, overflow: 'auto' }}>
            <Box >
                <Box className="poFormHeader">
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='h2'>Requests From Pharmacies</Typography>
                        {state.statusName != 'Approved' && <SaveButton isLoading={isSaveLoading} onClick={submit} title={'Sent'} />}
                    </Box>
                    <Divider sx={{ margin: '10px 0px' }} />
                </Box>
                {sentList.length > 0 && <SelectedProduct
                    title={"Selected:"}
                    selectedHeader={selectedHeader}
                    selectedParam={selectedParam}
                    list={sentList}
                    onRemove={onRemove}
                />}
                <Grid container >
                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <FilterBar placeholder={'Search...'} handleChange={handleSearch} /> */}
                        <SearchBar placeholder={'Search...'} handleChange={setSearch} />
                    </Grid>
                </Grid>
                <Card style={{ flex: 1, display: 'flex', overflowX: 'auto' }}>

                    <TableContainer tableHeader={tableHeader}
                        data={Products.filter(val => String(val?.diN_Master).includes(search) || val.productName.toLowerCase().includes(search.toLowerCase()))}
                        verifyParam={verifyParam}
                        isLoading={isLoading}
                        action={(data) => <TableAction data={data} />}
                    />
                </Card>
            </Box>
        </Card>
    );
};

export default RequestReceiveForm;


