import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import pharmacy from '../../assets/pharmacy.png'
import './vendor.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createPharmacy } from "../../store/actions/pharmacy";
import { setDataObject } from "../../utils/Utils";
import { getCities, getStates } from "../../store/actions/address";
import { createVendor } from "../../store/actions/vendor";
import { createRecord } from "../../store/reducers/vendor-slice";
import SaveButton from "../../components/saveButton/saveButton";
const VendorForm = (props) => {
    const { setOpen } = props
    const dispatch = useDispatch();
    const { company } = useSelector(state => state.company)
    const { user } = useSelector(state => state.auth)

    const [state, setState] = useState([])
    const [city, setCity] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const { countries } = useSelector(state => state.address)

    const defaultValues = useForm({
        defaultValues: {
            VendorName: "",
            EmailAddress: "",
            Website: '',
            Phone: "",
            Abbrevation: "",
            City_ID: "",
            CreatedBy: "",
            Address: "",
            Company_ID: "",
            country: "",
            state: ""
        },
    });
    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;


    useEffect(() => {
        if (watch('country') != "") {
            dispatch(getStates({ country_code: watch('country') })).then((res) => {
                setState(res.payload)
            })
        }
    }, [watch('country')])

    useEffect(() => {
        if (watch('state') != "") {
            dispatch(getCities({ country_code: watch('country'), state_code: watch('state') })).then((res) => {
                setCity(res.payload)
            })
        }
    }, [watch('state')])



    console.log(user)

    const submit = () => {
        setIsLoading(true)
        let obj = { ...getValues() }
        delete obj.country
        delete obj.state
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTVENDOR",
            "data": setDataObject({ ...getValues(), "Company_ID": user?.businessId, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        dispatch(createVendor(data)).then(res => {
            setIsLoading(false)
            let obj = res.payload
            console.log(obj)
            dispatch(createRecord(obj))
            setOpen(false)

        })
    }


    return <Card style={{ flex: 1, }}>
        <Box className="vendorFormContainer">
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='vendorProfileContainer'>
                    <ProfilePhoto image={pharmacy} />
                    <ActionButton label={'Upload vendor Photo'} />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Vendor Information</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="VendorName"
                            type="text"
                            label="Vendor Name"
                            control={control}
                            rules={{
                                required: "Vendor Name is required",
                            }}
                            error={errors?.VendorName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Abbrevation"
                            type="text"
                            label="Abbrevation"
                            control={control}
                            rules={{
                                required: "Abbrevation is required",
                            }}
                            error={errors?.Abbrevation}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="EmailAddress"
                            type="text"
                            label="Email Address"
                            control={control}
                            rules={{
                                required: "Email Address is required",
                            }}
                            error={errors?.EmailAddress}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Website"
                            type="text"
                            label="Website"
                            control={control}
                            rules={{
                                required: "Website is required",
                            }}
                            error={errors?.Website}
                        />
                    </Grid>
                    {user?.userType == 1 && <Grid item xs={4} >
                        <SelectFields
                            fieldName="Company_ID"
                            label="Company"
                            control={control}
                            options={company.map(({ companyName, id }) => {
                                return { label: companyName, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.Company_ID}
                        />
                    </Grid>
                    }
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Phone"
                            type="text"
                            label="Phone"
                            control={control}
                            rules={{
                                required: "Phone is required",
                            }}
                            error={errors?.Phone}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Address</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Address"
                            type="text"
                            label="Address"
                            control={control}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="country"
                            label="Country"
                            control={control}
                            options={countries.map(({ name, iso2, }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="state"
                            label="State"
                            control={control}
                            disabled={!watch('country')}
                            options={state?.map(({ name, iso2 }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="City_ID"
                            label="City"
                            control={control}
                            disabled={!watch('state')}
                            options={city?.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default VendorForm;
