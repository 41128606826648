import { createSlice } from "@reduxjs/toolkit";
import { getAllVendor } from "../actions/vendor";

const initialState = {
  vendor: [],
  totalcount: 0,
}

const vendorSlice = createSlice({
  name: "vendor",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllVendor.pending, (state, action) => {
      state.vendor = []
    });
    builder.addCase(getAllVendor.fulfilled, (state, action) => {
      state.vendor = action.payload.data
    });
  },
  reducers: {
    createRecord: (state, action) => {
      state.vendor.unshift(action.payload)
    },
  },
});
export default vendorSlice.reducer;

export const { createRecord } = vendorSlice.actions;
