import { Button, CircularProgress } from "@mui/material";
import React from "react";

const SaveButton = (props) => {
    const { isLoading, onClick, title } = props

    return (
        <Button size="medium" variant="contained" color="primary" disabled={isLoading} onClick={onClick}>
            {isLoading && (
                <CircularProgress size={20} sx={{ marginRight: 1, color: "#fff" }} />
            )}
            {title}</Button>
    );
};

export default SaveButton;
