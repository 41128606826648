import { Button } from "@mui/material";
import React from "react";
const ActionButton = ({ label, onClick, style }) => {

    return (
        <Button size="medium" variant="contained" sx={{ backgroundImage: 'linear-gradient(to right, #44289d , #536fcf)' }} style={style} onClick={onClick}>
            {label}</Button>
    );
};

export default ActionButton;
