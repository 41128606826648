import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { filterName } from "../../utils/Utils";
import { userRole } from "../../utils/lists";
import { getAllUser } from "../../store/actions/user";
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import UsersForm from "./usersForm";
import edit from '../../assets/edit.png'

const UsersProfile = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { user } = useSelector(state => state.user)

    const auth = useSelector(state => state.auth)
    const [users, setUsers] = useState([])

    const [open, setOpen] = React.useState(false);

    const tableHeader = ['First Name', 'Last Name', 'User Name', 'Email', 'User Profile']
    const verifyParam = ['firstName', 'lastName', 'userName', 'email', 'Role']

    useEffect(() => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLUSER",
            "data": { "CompanyID": auth.user?.businessId },
            "debug": null,
            "token": null
        }
        dispatch(getAllUser(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        console.log(user)
        user.forEach((ele) => {
            const obj = { ...ele }
            console.log(ele)
            obj['Role'] = filterName(userRole, ele.userProfile_ID);
            arr.push(obj)
        })
        setUsers(arr)

    }, [user])

    const hasPermission = useHasPermission(['Users', 'edit'])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Users</Typography>
                <PermissionComp list={['Users', 'create']}>
                    <ModalButton open={open} setOpen={setOpen} tooltip={'create'} type={'Button'}>
                        <UsersForm setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1, overflow: 'auto' }}>
                <TableContainer tableHeader={tableHeader}
                    data={users}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={hasPermission ? TableAction : null} />
            </Card>
        </>
    );
};

export default UsersProfile;


const TableAction = ({ id }) => {
    const [openEdit, setOpenEdit] = React.useState(false);

    return (
        <PermissionComp list={['Users', 'edit']}>
            <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit User'} img={edit} type={'icon'}>
                <UsersForm data={id} setOpen={setOpenEdit} />
            </ModalButton>
        </PermissionComp>
    )
}