export const apiURL = "https://anasrahat-001-site1.ltempurl.com/api/Main/Post";

export const api = {

  //================Auth================
  generalCall: () => {
    return {
      url: `${apiURL}`,
      method: "post",
    };
  },
  getCountries: () => {
    return {
      url: `https://api.countrystatecity.in/v1/countries`,
      method: "get",
    };
  },
  getStates: (data) => {
    return {
      url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states`,
      method: "get",
    };
  },
  getCities: (data) => {
    return {
      url: `https://api.countrystatecity.in/v1/countries/${data.country_code}/states/${data.state_code}/cities`,
      method: "get",
    };
  },
};
