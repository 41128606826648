import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import officeProfile from '../../assets/officeProfile.png'
import './company.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useDispatch, useSelector } from "react-redux";
import { createCompany, updateCompany } from "../../store/actions/company";
import { useNavigate } from "react-router-dom";
import { getCities, getStates } from "../../store/actions/address";
import { setDataObject } from "../../utils/Utils";
import { createRecord, updateRecord } from "../../store/reducers/company-slice";
import SaveButton from "../../components/saveButton/saveButton";
const CompanyForm = (props) => {
    const { data, setOpen } = props
    const dispatch = useDispatch();
    const navigation = useNavigate()
    const { countries } = useSelector(state => state.address)
    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false)

    const [state, setState] = useState([])
    const [city, setCity] = useState([])

    const defaultValues = useForm({
        defaultValues: {
            CompanyName: "",
            EmailAddress: "",
            Website: "",
            Phone: "",
            country: "",
            CityId: "",
            Address: "",
            state: ""
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;


    useEffect(() => {
        console.log(data)
        if (data?.id) {
            reset({
                CompanyName: data.companyName || "",
                EmailAddress: data.emailAddress || "",
                Website: data.website || "",
                Phone: data.phone || "",
                country: data.code || "",
                CityId: data.cityID || "",
                Address: data.code || "",
                state: data.code || ""
            });
        }
    }, []);


    useEffect(() => {
        if (watch('country') != "") {
            dispatch(getStates({ country_code: watch('country') })).then((res) => {
                setState(res.payload)
            })
        }
    }, [watch('country')])

    useEffect(() => {
        if (watch('state') != "") {
            dispatch(getCities({ country_code: watch('country'), state_code: watch('state') })).then((res) => {
                setCity(res.payload)
            })
        }
    }, [watch('state')])

    const submit = () => {
        setIsLoading(true)
        let obj = { ...getValues() }
        delete obj.country
        delete obj.state
        let saveObj = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTCOMPANY",
            "data": setDataObject({ ...obj, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }

        if (data?.id) {
            let updateObj = { ...saveObj }
            updateObj['data'] = { ...saveObj['data'], 'ID': data.id, 'EditBy': user?.userId, ISUPDATE: 1 }
            updateObj['msgType'] = 'UPDATECOMPANY'
            const sentObj = updateObj.data
            const saveObject = {
                "id": sentObj.ID,
                "companyName": sentObj.CompanyName,
                "emailAddress": sentObj.EmailAddress,
                "website": sentObj.Website,
                "phone": sentObj.Phone,
                "createdOn": data.createdOn,
                "createdBy": sentObj.CreatedBy,
                "editOn": data.editBy,
                "editBy": sentObj.EditBy,
                "cityID": sentObj.CityId,
                "isActive": data.isActive
            }
            dispatch(updateCompany(updateObj)).then(res => {
                setIsLoading(false)
                console.log(res)
                dispatch(updateRecord(saveObject))
                setOpen(false)
            })
        } else {
            dispatch(createCompany(saveObj)).then(res => {
                setIsLoading(false)
                let obj = res.payload
                obj['cityID'] = obj.cityId
                delete obj.grnTb
                delete obj.inventoryTb
                delete obj.pharmacyTb
                delete obj.poTb
                delete obj.productTb
                delete obj.userTb
                delete obj.wearHouseTb
                delete obj.cityId

                console.log(obj)
                dispatch(createRecord(obj))
                setOpen(false)
            })
        }

    }

    return <Card style={{ flex: 1, }}>
        <Box className="formContainer">
            <form>
                <Typography variant='h3' >Profile picture upload</Typography>
                <Box className='companyProfileContainer'>
                    <ProfilePhoto image={officeProfile} />
                    <ActionButton label={'Upload Company Photo'} />
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Company Information</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="CompanyName"
                            type="text"
                            label="Company Name"
                            control={control}
                            rules={{
                                required: "Company Name is required",
                            }}
                            error={errors?.CompanyName}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="EmailAddress"
                            type="text"
                            label="Email Address"
                            control={control}
                            rules={{
                                required: "Email Address is required",
                            }}
                            error={errors?.EmailAddress}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Website"
                            type="text"
                            label="Website"
                            control={control}
                            rules={{
                                required: "Website is required",
                            }}
                            error={errors?.Website}
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Phone"
                            type="text"
                            label="Phone"
                            control={control}
                            rules={{
                                required: "Phone is required",
                            }}
                            error={errors?.Phone}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <Typography variant='h3' >Address</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputFields
                            fieldName="Address"
                            type="text"
                            label="Address"
                            control={control}
                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="country"
                            label="Country"
                            control={control}
                            options={countries.map(({ name, iso2, }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="state"
                            label="State"
                            control={control}
                            disabled={!watch('country')}
                            options={state?.map(({ name, iso2 }) => {
                                return { label: name, value: iso2 };
                            })}

                        />
                    </Grid>
                    

                    <Grid item xs={4} >
                        <SelectFields
                            fieldName="CityId"
                            label="City"
                            control={control}
                            disabled={!watch('state')}
                            options={city?.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                        />
                    </Grid>

                    <Grid item xs={12}>
                        <ButtonContainer isSingle>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default CompanyForm;
