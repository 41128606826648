import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Card from "../../components/card/Card";
import ButtonContainer from "../../components/buttonContainer";
import InputFields from "../../components/InputFields";
import SelectFields from "../../components/SelectFields";
import './grn.scss'
import ActionButton from "../../components/actionButton";
import { ProfilePhoto } from "../../components/profilePhoto/profilePhoto";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createGrn } from "../../store/actions/grn";
import DatePickerFields from "../../components/datePickerField";
import { setDataObject } from "../../utils/Utils";
import SaveButton from "../../components/saveButton/saveButton";

const GrnForm = (props) => {
    const {setOpen, poID}=props
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const { company } = useSelector(state => state.company)
    const { warehouse } = useSelector(state => state.warehouse)
    const { user } = useSelector(state => state.auth)
    const [isLoading, setIsLoading] = useState(false);


    const { wearhouseList } = useSelector(state => state.filler)

    const defaultValues = useForm({
        defaultValues: {
            GrnNo: "",
            Grndate: "",
            WearHouseId: "",
        },
    });

    const {
        control,
        handleSubmit,
        setError,
        clearErrors,
        watch,
        reset,
        resetField,
        getValues,
        formState: { errors },
    } = defaultValues;

    const submit = () => {
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "INSERTGRN",
            "data": setDataObject({ ...getValues(), "PoId": poID, "CompanyId": user?.businessId, "CreatedBy": user?.userId }),
            "debug": null,
            "token": null
        }
        if (user?.userType != 1) {
            data['data']['CompanyId'] = user?.businessId
        }
        dispatch(createGrn(data)).then(res => {
            setIsLoading(false)
            setOpen(false)
        })
    }


    return <Card style={{ flex: 1, }}>
        <Box className="grnFormContainer">
            <Typography variant='h2' >General Information</Typography>
            <Divider sx={{ margin: '10px 0px' }} />
            <form>
                {/* <Typography variant='h3' >Profile picture upload</Typography> */}
                {/* <Box className='poProfileContainer'>
                    <ProfilePhoto image={po} />
                    <ActionButton label={'Upload Po Photo'} />
                </Box> */}
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <Typography variant='h3' >GRN Information</Typography>
                    </Grid>
                    <Grid item xs={6} >
                        <InputFields
                            fieldName="GrnNo"
                            type="text"
                            label="GRN No"
                            control={control}
                            rules={{
                                required: "GRn No is required",
                            }}
                            error={errors?.GrnNo}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePickerFields
                            fieldName="Grndate"
                            type="date"
                            label="Grn Date "
                            control={control}
                            rules={{
                                required: "Grn Date is required",
                            }}
                            error={errors?.Grndate}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <SelectFields
                            fieldName="WearHouseId"
                            label="WearHouse"
                            control={control}
                            options={wearhouseList.map(({ name, id }) => {
                                return { label: name, value: id };
                            })}

                            rules={{
                                required: "WearHouse is required",
                            }}
                            error={errors?.WearHouseId}
                        />
                    </Grid>

                    {user?.userType == 1 && <Grid item xs={6} >
                        <SelectFields
                            fieldName="CompanyID"
                            label="Company"
                            control={control}
                            options={company.map(({ companyName, id }) => {
                                return { label: companyName, value: id };
                            })}

                            rules={{
                                required: "Company is required",
                            }}
                            error={errors?.CompanyID}
                        />
                    </Grid>}

                    <Grid item xs={12}>
                        <ButtonContainer>
                            <SaveButton isLoading={isLoading} onClick={handleSubmit(submit)} title={'Save'} />
                        </ButtonContainer>
                    </Grid>
                </Grid>
            </form>
        </Box>
    </Card>
};

export default GrnForm;
