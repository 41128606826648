import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button } from '@mui/material';
import Card from "../../components/card/Card";
import ActionButton from "../../components/actionButton";
import TableContainer from "../../components/table/table";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllProductDetail } from "../../store/actions/product";
import details from '../../assets/details.png'
import edit from '../../assets/edit.png'
import PermissionComp, { useHasPermission } from "../../components/permissionComp/permissionComp";
import ModalButton from "../../components/modalButton/modalButton";
import ProductDetailForm from "./productDetailForm";
import { filterName } from "../../utils/Utils";

const ProductDetail = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigation = useNavigate()

    const location = useLocation();
    const { state } = props

    const { productDetail } = useSelector(state => state.product)

    const [ProductDetails, setProductDetails] = useState([])
    const [open, setOpen] = React.useState(false);
    const { packSizeList } = useSelector(state => state.filler)

    const tableHeader = ['Product Name', 'Strenght', 'Pack Size', 'Expiry']
    const verifyParam = ['productName', 'mg', 'packSizeValue', 'expiryTo']

    useEffect(() => {
        console.log(state)
        setIsLoading(true)
        let data = {
            "sid": null,
            "lid": null,
            "reqInfo": null,
            "msgType": "GETALLPRODUCTDETAIL",
            "data": { "ProductID": state.id },
            "debug": null,
            "token": null
        }
        dispatch(getAllProductDetail(data)).then(res => {
            setIsLoading(false)
        })
    }, [])

    useEffect(() => {

        let arr = []
        console.log(productDetail)
        productDetail.forEach((ele) => {
            const obj = { ...ele }
            obj['isActive'] = obj.isActive ? "Active" : obj.isActive ? "Non-Active" : '';
            obj['packSizeValue'] = filterName(packSizeList, obj.packSize_ID)
            arr.push(obj)
        })
        setProductDetails(arr)

    }, [productDetail])

    const hasPermission = useHasPermission(['Products', 'detail', 'edit'])

    return (
        <>
            <Box sx={{ margin: '15px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h1' >Product</Typography>
                <PermissionComp list={['Products', 'detail', 'create']}>
                    {/* <ActionButton label={'Create Product'} onClick={() => navigation('create', { state: state.id })} /> */}
                    <ModalButton open={open} setOpen={setOpen} tooltip={'Create Product Detail'} type={'Button'}>
                        <ProductDetailForm state={state.id} setOpen={setOpen} />
                    </ModalButton>
                </PermissionComp>
            </Box>
            <Card style={{ flex: 1 }}>
                <TableContainer tableHeader={tableHeader}
                    data={ProductDetails}
                    verifyParam={verifyParam}
                    isLoading={isLoading}
                    action={hasPermission ? TableAction : null}
                />
            </Card>
        </>
    );
};

export default ProductDetail;




const TableAction = ({ id }) => {
    const [openEdit, setOpenEdit] = React.useState(false);
    console.log(id)

    return (
        <Box>
            <PermissionComp list={['Products', 'detail', 'edit']}>
                <ModalButton open={openEdit} setOpen={setOpenEdit} tooltip={'Edit User'} img={edit} type={'icon'}>
                    <ProductDetailForm data={id} setOpen={setOpenEdit} />
                </ModalButton>
            </PermissionComp>
        </Box>
    )
}