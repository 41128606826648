import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './routes';
import { useContext, useEffect } from 'react';
import { ThemeSelectorContext } from './store/context/themeSelectore';
import generateTheme from './layouts/theme';
import { ThemeProvider } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import Notification from './components/Notification';
import './scrollbar.css'
import Login from './screens/login/login';
import { getCountries } from './store/actions/address';
import { logoutUser, saveUserData } from './store/reducers/auth-slice';
import { getAllCompanyFillers, getAllPackSizeFillers, getAllPharmacyFillers, getAllProductFillers, getAllVendorFillers, getAllWearHouseFillers } from './store/actions/fillers';

function App() {
  const routing = useRoutes(routes);
  const themeCtxt = useContext(ThemeSelectorContext)

  const dispatch = useDispatch();

  const { notification } = useSelector((state) => state.ui);
  const { user } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(getCountries())
  }, [])

  useEffect(() => {
    const ims = localStorage.getItem('IMS');
    if (!JSON.parse(ims)?.permission) {
      dispatch(logoutUser())
    }
    if (ims) {
      dispatch(saveUserData(JSON.parse(ims)));
    }

  }, [])

  useEffect(() => {
    console.log(user)
    if (user)
      fetchFilterData()
  }, [user])


  const fetchFilterData = async () => {
    console.log(user)
    const filterObj = {
      "sid": null,
      "lid": null,
      "reqInfo": null,
      "msgType": "GETALLFILLERBYTYPE",
      "data": { "Company_ID": user?.businessId },
      "debug": null,
      "token": null
    };
    await dispatch(getAllCompanyFillers(filterObj));
    await dispatch(getAllWearHouseFillers(filterObj));
    await dispatch(getAllVendorFillers(filterObj));
    await dispatch(getAllPharmacyFillers(filterObj));
    await dispatch(getAllPackSizeFillers(filterObj));
  }

  useEffect(() => {
    toggleDarkMode(themeCtxt.themeSelector)
  }, [themeCtxt])


  function toggleDarkMode(isDarkMode) {
    document.body.classList.toggle('dark-mode', isDarkMode);
  }

  const theme = localStorage.getItem("MISTheme")
  const themeSelector = JSON.parse(theme)

  const lightTheme = generateTheme("light");
  const darkTheme = generateTheme("dark");


  return (
    <ThemeProvider theme={themeSelector ? darkTheme : lightTheme}>
      {notification && (
        <Notification
          status={notification.status}
          title={notification.title}
          message={notification.message}
        />
      )}
      {routing}
    </ThemeProvider>
  );
}

export default App;
