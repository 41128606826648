import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function TablePaginationComp(props) {
    const { count, fetchList } = props
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        console.log(newPage)
        setPage(newPage);
        fetchList({},rowsPerPage,newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        fetchList({},parseInt(event.target.value, 10),0)
    };

    return (
        <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            color='secondary'
            showLastButton
            showFirstButton
        />
    );
}