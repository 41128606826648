import moment from "moment";

export const validPhone = /[^0-9+()-]|([+-])\1+/g
export const onlyPhone = (str) => {
  if (str.length > 0) {
    str = str?.replace(validPhone, '')
  }
  return str;
}

export const filterName = (list, id) => {
  return list?.find(val => val?.id == parseInt(id))?.name || ""
}


export const setDataObject = (obj) => {

  Object.keys(obj).filter(item => {
    if (item.includes('date') || item.includes('From') || item.includes('To')) {
      if (obj[item] == '') {
        obj[item] = ""
      } else {
        obj[item] = moment(obj[item]).format('YYYY-MM-DD')
      }
    } else if (obj[item] == '' || obj[item] == null) {
      obj[item] = ""
    }
  })
  return obj
}

export const alphaRegex = /^[A-Za-z ]*$/
export const numberRegex = /^\d*$/
export const idRegex = /^[\d-]*$/
export const decimalRegex = /^[\d.]*$/
export const phoneRegex = /^[\d+]*$/
export const alphanumericRegex = /^[a-zA-Z0-9 ]*$/
export const alphaSpecialCharRegex = /^[a-zA-Z!@#$%^&*(),.?":{}|<>]+$/