import { createSlice } from "@reduxjs/toolkit";
import { getAllProduct, getAllProductDetail } from "../actions/product";

const initialState = {
  product: [],
  totalcount: 0,
  productDetail: [],
}

const productSlice = createSlice({
  name: "product",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(getAllProduct.pending, (state, action) => {
      state.product = []
    });
    builder.addCase(getAllProduct.fulfilled, (state, action) => {
      state.product = action.payload.list
      state.totalcount = action.payload.total
    });
    builder.addCase(getAllProductDetail.pending, (state, action) => {
      state.productDetail = []
    });
    builder.addCase(getAllProductDetail.fulfilled, (state, action) => {
      state.productDetail = action.payload.data
    });
  },
  reducers: {
    createProductRecord: (state, action) => {
      state.product.unshift(action.payload)
    },
    updateRecord: (state, action) => {
      const getIndex = state.product.findIndex(item => item.id == action.payload.id)
      state.product[getIndex] = action.payload;
    },
    updateProductDetailRecord: (state, action) => {
      const getIndex = state.productDetail.findIndex(item => item.productID == action.payload.productID)
      state.productDetail[getIndex] = action.payload;
    },
  },
});
export default productSlice.reducer;
export const { createProductRecord, updateRecord,updateProductDetailRecord } = productSlice.actions;
