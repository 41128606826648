import axios from 'axios';

const getHeaders = file => {
  const headers = {
    "Content-Type": "application/json",
  };

  return headers;
};
const call = (api, data, thunk, file) => {
  let headers = getHeaders(file);
  return axios[api.method]?.(api.url, data, { headers })
    .then(response => {
      return response;
    })
    .catch(error => {
      console.log(error)
    });
};

export default {
  call,
};
