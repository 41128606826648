import * as React from 'react';
import Box from '@mui/material/Box';
import './profilePhoto.scss'

export const ProfilePhoto = (props) => {

    return (
        <Box className={`profilePhotoContainer ${props.classname}`}>
            <img src={props.image} />
        </Box>
    );
}