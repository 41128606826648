import React, { useEffect, useState } from "react";
import { Tooltip, Box, Typography, IconButton, Button, TextField, Grid } from '@mui/material';
import Card from "../../components/card/Card";
import TableContainer from "../../components/table/table";
import { filterName } from "../../utils/Utils";
import { useSelector } from "react-redux";

const FillRequestData = ({ data, addOrderList }) => {

    const tableHeader = ['Expiry', 'Avaiable Quantity', 'Pack Size']
    const verifyParam = ['expiryTo', 'productDetailQty', 'packSizeValue']
    const [list, setList] = useState([])
    const { packSizeList } = useSelector(state => state.filler)

    useEffect(() => {
        let arr = []
        data.subProduct.forEach((ele) => {
            const obj = { ...ele }
            obj['Qty'] = '';
            obj['packSizeValue'] = filterName(packSizeList, ele.packSize_ID)
            arr.push(obj)
        })
        setList(arr)
    }, [data.subProduct])

    const handleChange = (qty, paramName, data) => {
        console.log(data, qty, list)
        const arr = [...list]
        arr[data.index][paramName] = qty
    }




    const TableAction = ({ data }) => {

        const [localInputValue, setLocalInputValue] = useState(list[data.index]?.Qty || '');

        const handleInputChange = (e) => {
            const value = e.target.value;
            console.log(value)
            if (value <= list[data.index]?.productDetailQty) {
                setLocalInputValue(value);
                handleChange(value, 'Qty', data);
            }
        };
        return (
            <TextField
                size="small"
                value={localInputValue}
                onChange={handleInputChange}
                label="Quantity"
                type={'number'}
            />
        )
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6} sx={{ display: 'flex' }}>
                    <Typography variant="h2">Product Detail:</Typography>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" onClick={() => addOrderList(list.filter(val => val.Qty != ''))}>Save</Button>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">Product Name:</Typography>
                    <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{data.productName}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">DIN Master:</Typography>
                    <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{data.diN_Master}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">Strength:</Typography>
                    <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{data.mg}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">Vendor:</Typography>
                    <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{data.vendorValue}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h4">Available Quantity:</Typography>
                    <Typography variant="body1" sx={{ marginLeft: '10px', textWrap: 'wrap' }}>{data.productQty}</Typography>
                </Grid>

            </Grid>
            <TableContainer
                tableHeader={tableHeader}
                data={list}
                verifyParam={verifyParam}
                // isLoading={isLoading}
                actionText={"Quantity Request"}
                action={(data) => <TableAction data={data} />}
            />
        </>
    )
};

export default FillRequestData;