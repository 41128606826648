export const userRole = [
    {
        name: 'Super Admin',
        id: '1'
    },
    {
        name: 'Admin',
        id: '2'
    },
    {
        name: 'Warehouse Manager',
        id: '3'
    },
    {
        name: 'Pharmacy',
        id: '4'
    }
]


export const productsList = [
    {
        "DinMaster": 2396769,
        "ProductCompany": "APO",
        "ProductName": "ABACAVIR",
        "Mg": "300mg",
        "PackSizeMaster": "60's",
        "Qty": 2,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2229977,
        "ProductCompany": "APO",
        "ProductName": "ACETAMINOPHEN CAP",
        "Mg": "500 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 23,
        "Expiry": "Dec-26"
    },
    {
        "ProductCompany": "APO",
        "ProductName": "ACETAMINOPHEN ",
        "Mg": "325mg",
        "PackSizeMaster": "1000's",
        "Qty": 9
    },
    {
        "DinMaster": 2229873,
        "ProductCompany": "APO",
        "ProductName": "ACETAMINOPHEN CAP",
        "Mg": "325 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 8,
        "Expiry": "Nov-26"
    },
    {
        "DinMaster": 2402769,
        "ProductCompany": "APO",
        "ProductName": "ALLOPURINOL TAB",
        "Mg": "100 mg",
        "PackSizeMaster": "100's\/1000's",
        "Qty": 12,
        "Expiry": "Apr-25"
    },
    {
        "DinMaster": 2402777,
        "ProductCompany": "APO",
        "ProductName": "ALLOPURINOL TAB",
        "Mg": "200 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2402785,
        "ProductCompany": "APO",
        "ProductName": "ALLOPURINOL TAB",
        "Mg": "300 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2402785,
        "ProductCompany": "APO",
        "ProductName": "ALLOPURINOL TAB",
        "Mg": "300 mg",
        "PackSizeMaster": "500'S",
        "Qty": 1,
        "Expiry": "Mar-27"
    },
    {
        "DinMaster": 2315866,
        "ProductCompany": "APO",
        "ProductName": "Alfuzosin Tab",
        "Mg": "10mg",
        "PackSizeMaster": "100's",
        "Qty": 11,
        "Expiry": "May-26"
    },
    {
        "DinMaster": 2387875,
        "ProductCompany": "APO",
        "ProductName": "Alysena 21 Tab",
        "Mg": "100mcg\/20mcg",
        "PackSizeMaster": "21's",
        "Qty": 12,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2387883,
        "ProductCompany": "APO",
        "ProductName": "Alysena 28 Tab",
        "Mg": "100mcg\/20mcg",
        "PackSizeMaster": "28's",
        "Qty": 6,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2246194,
        "ProductCompany": "APO",
        "ProductName": "Amiodarone",
        "Mg": "200mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2403161,
        "ProductCompany": "APO",
        "ProductName": "AMITRIPTYLINE TAB",
        "Mg": "75 mg",
        "PackSizeMaster": "100'S",
        "Qty": 5,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2273373,
        "ProductCompany": "APO",
        "ProductName": "AMLODIPINE TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "500'S",
        "Qty": 14,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2273381,
        "ProductCompany": "APO",
        "ProductName": "AMLODIPINE TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "500'S",
        "Qty": 26,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 628131,
        "ProductCompany": "APO",
        "ProductName": "Amoxi Susp",
        "Mg": "125mg\/5ml",
        "PackSizeMaster": "100ml",
        "Qty": 18,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 628131,
        "ProductCompany": "APO",
        "ProductName": "Amoxi Susp",
        "Mg": "125mg\/5ml",
        "PackSizeMaster": "150ml",
        "Qty": 25,
        "Expiry": "Apr-25"
    },
    {
        "DinMaster": 324019,
        "ProductCompany": "APO",
        "ProductName": "Anafranil Tab",
        "Mg": "25mg",
        "PackSizeMaster": "100's",
        "Qty": 6,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2471086,
        "ProductCompany": "APO",
        "ProductName": "ARIPIPRAZOLE TAB  ",
        "Mg": "2 mg",
        "PackSizeMaster": "30'S",
        "Qty": 0,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2471094,
        "ProductCompany": "APO",
        "ProductName": "ARIPIPRAZOLE TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "30'S",
        "Qty": 0,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2471108,
        "ProductCompany": "APO",
        "ProductName": "ARIPIPRAZOLE TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "30'S",
        "Qty": 0,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2471116,
        "ProductCompany": "APO",
        "ProductName": "ARIPIPRAZOLE TAB",
        "Mg": "15 mg",
        "PackSizeMaster": "30'S",
        "Qty": 0,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2471124,
        "ProductCompany": "APO",
        "ProductName": "ARIPIPRAZOLE TAB",
        "Mg": "20 mg",
        "PackSizeMaster": "30'S",
        "Qty": 4,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2295261,
        "ProductCompany": "APO",
        "ProductName": "ATORVASTATIN TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "500'S",
        "Qty": 24,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2295288,
        "ProductCompany": "APO",
        "ProductName": "ATORVASTATIN TAB",
        "Mg": "20 mg",
        "PackSizeMaster": "500'S",
        "Qty": 28,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2295296,
        "ProductCompany": "APO",
        "ProductName": "ATORVASTATIN TAB",
        "Mg": "40 mg",
        "PackSizeMaster": "500'S",
        "Qty": 14,
        "Expiry": "Apr-25"
    },
    {
        "DinMaster": 2295318,
        "ProductCompany": "APO",
        "ProductName": "ATORVASTATIN TAB",
        "Mg": "80 mg",
        "PackSizeMaster": "100'S",
        "Qty": 20,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2256134,
        "ProductCompany": "APO",
        "ProductName": "BISOPROLOL TAB",
        "Mg": "5mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 2256177,
        "ProductCompany": "APO",
        "ProductName": "BISOPROLOL TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "Jan-26"
    },
    {
        "DinMaster": 2375311,
        "ProductCompany": "APO",
        "ProductName": "Brimonidine-Timol",
        "Mg": "0.2%\/0.5%",
        "PackSizeMaster": "10ml",
        "Qty": 0,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2455897,
        "ProductCompany": "APO",
        "ProductName": "Cabergoline Tab",
        "Mg": "0.5mg",
        "PackSizeMaster": "8's",
        "Qty": 8,
        "Expiry": "Nov-24"
    },
    {
        "DinMaster": 2240774,
        "ProductCompany": "APO",
        "ProductName": "Cefadroxil cap",
        "Mg": "500mg",
        "PackSizeMaster": "100's",
        "Qty": 2,
        "Expiry": "Jan-27"
    },
    {
        "DinMaster": 2274752,
        "ProductCompany": "APO",
        "ProductName": "CLARITHROMYCIN TAB",
        "Mg": "500 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2413345,
        "ProductCompany": "APO",
        "ProductName": "CLARITHROMYCIN XL TAB",
        "Mg": "500 mg",
        "PackSizeMaster": "100'S",
        "Qty": 1,
        "Expiry": "Jul-25"
    },
    {
        "DinMaster": 2252767,
        "ProductCompany": "APO",
        "ProductName": "CLOPIDOGREL TAB",
        "Mg": "75 mg",
        "PackSizeMaster": "100'S",
        "Qty": 14,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2284030,
        "ProductCompany": "APO",
        "ProductName": "Desmopressin Tab",
        "Mg": "0.1 mg",
        "PackSizeMaster": "100's",
        "Qty": 2,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2284049,
        "ProductCompany": "APO",
        "ProductName": "Desmopressin Tab",
        "Mg": "0.2 mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Oct-23"
    },
    {
        "DinMaster": 839175,
        "ProductCompany": "APO",
        "ProductName": "DICLOFENAC NA  TAB",
        "Mg": "25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Oct-24"
    },
    {
        "DinMaster": 839183,
        "ProductCompany": "APO",
        "ProductName": "DICLO 50mg  TAB",
        "Mg": "50 mg",
        "PackSizeMaster": "100'S",
        "Qty": 7,
        "Expiry": "May-27"
    },
    {
        "DinMaster": 2302624,
        "ProductCompany": "PMS",
        "ProductName": "PMS-DICLOFENAC NA  TAB",
        "Mg": "50 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Jul-24"
    },
    {
        "DinMaster": 2162814,
        "ProductCompany": "APO",
        "ProductName": "DICLOFENAC NA  SR TAB",
        "Mg": "75 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Jul-26"
    },
    {
        "DinMaster": 2091194,
        "ProductCompany": "APO",
        "ProductName": "DICLOFENAC NA  SR TAB",
        "Mg": "100 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Jul-26"
    },
    {
        "DinMaster": 2230997,
        "ProductCompany": "APO",
        "ProductName": "Diltiaz CD",
        "Mg": "120mg",
        "PackSizeMaster": "100's",
        "Qty": 6,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2230998,
        "ProductCompany": "APO",
        "ProductName": "Diltiaz CD",
        "Mg": "180mg",
        "PackSizeMaster": "100's",
        "Qty": 5,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2230999,
        "ProductCompany": "APO",
        "ProductName": "Diltiaz CD",
        "Mg": "240mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Sep-23"
    },
    {
        "DinMaster": 2242540,
        "ProductCompany": "TEVA",
        "ProductName": "Diltiazem CD Cap",
        "Mg": "240mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Oct-26"
    },
    {
        "DinMaster": 2229526,
        "ProductCompany": "APO",
        "ProductName": "Diltiaz CD",
        "Mg": "300mg",
        "PackSizeMaster": "100's",
        "Qty": 2,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2362260,
        "ProductCompany": "APO",
        "ProductName": "DONEPEZIL  TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2362279,
        "ProductCompany": "APO",
        "ProductName": "DONEPEZIL  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2240588,
        "ProductCompany": "APO",
        "ProductName": "Doxazosin Tab",
        "Mg": "1mg",
        "PackSizeMaster": "100's",
        "Qty": 5,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 740713,
        "ProductCompany": "APO",
        "ProductName": "Doxy",
        "Mg": "100mg",
        "PackSizeMaster": "100's",
        "Qty": 1,
        "Expiry": "May-29"
    },
    {
        "DinMaster": 2413248,
        "ProductCompany": "APO",
        "ProductName": "DOXYLAMIN-PYRIDOXINE",
        "Mg": "10 mg\/10mg",
        "PackSizeMaster": "500's",
        "Qty": 1,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2404206,
        "ProductCompany": "APO",
        "ProductName": "Dutasteride Cap",
        "Mg": "0.5 mg",
        "PackSizeMaster": "100's",
        "Qty": 33,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2452006,
        "ProductCompany": "APO",
        "ProductName": "Emtricitabin-Tenovir Tab",
        "Mg": "200mg\/300mg",
        "PackSizeMaster": "30's",
        "Qty": 2,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2292041,
        "ProductCompany": "APO",
        "ProductName": "FAMCICLOVIR  TAB",
        "Mg": "250 mg",
        "PackSizeMaster": "30'S",
        "Qty": 0,
        "Expiry": "Mar-24"
    },
    {
        "DinMaster": 2292068,
        "ProductCompany": "APO",
        "ProductName": "FAMCICLOVIR  TAB",
        "Mg": "500 mg",
        "PackSizeMaster": "30'S",
        "Qty": 2,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2452367,
        "ProductCompany": "APO",
        "ProductName": "Feldipine Tab",
        "Mg": "2.5 mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Dec-23"
    },
    {
        "DinMaster": 2237370,
        "ProductCompany": "APO",
        "ProductName": "Fluconazole Tab",
        "Mg": "50mg",
        "PackSizeMaster": "50's",
        "Qty": 3,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2245292,
        "ProductCompany": "MYLAN",
        "ProductName": "Fluconazole Tab",
        "Mg": "50mg",
        "PackSizeMaster": "50's",
        "Qty": 8,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2237371,
        "ProductCompany": "APO",
        "ProductName": "Fluconazole Tab",
        "Mg": "100mg",
        "PackSizeMaster": "50's",
        "Qty": 0,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2245293,
        "ProductCompany": "MYLAN",
        "ProductName": "Fluconazole Tab",
        "Mg": "100mg",
        "PackSizeMaster": "50's",
        "Qty": 2,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2241895,
        "ProductCompany": "APO",
        "ProductName": "FLUCONAZOLE  CAP",
        "Mg": "150 mg",
        "PackSizeMaster": "1'S",
        "Qty": 0,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2294745,
        "ProductCompany": "APO",
        "ProductName": "FLUTICASONE SPRAY",
        "Mg": "50mcg",
        "PackSizeMaster": "120 ML",
        "Qty": 32,
        "Expiry": "Jul-26"
    },
    {
        "DinMaster": 2526557,
        "ProductCompany": "APO",
        "ProductName": "FLUTICASONE HFA",
        "Mg": "125 mcg",
        "PackSizeMaster": "120 METERED",
        "Qty": 75,
        "Expiry": "Jul-25"
    },
    {
        "DinMaster": 2510987,
        "ProductCompany": "APO",
        "ProductName": "FLUTICASONE HFA",
        "Mg": "250 mcg",
        "PackSizeMaster": "120 METERED",
        "Qty": 36,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2231328,
        "ProductCompany": "APO",
        "ProductName": "Fluoxetine Sol",
        "Mg": "20mg\/5ml",
        "PackSizeMaster": "120 ML",
        "Qty": 34,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2231329,
        "ProductCompany": "APO",
        "ProductName": "FLUVOXAMINE  TAB",
        "Mg": "50 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "June-25"
    },
    {
        "DinMaster": 2231330,
        "ProductCompany": "APO",
        "ProductName": "FLUVOXAMINE  TAB",
        "Mg": "100 mg",
        "PackSizeMaster": "100'S",
        "Qty": 5,
        "Expiry": "Mar-26"
    },
    {
        "DinMaster": 2266016,
        "ProductCompany": "APO",
        "ProductName": "Fosinopril",
        "Mg": "20mg ",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Dec-23"
    },
    {
        "DinMaster": 2293358,
        "ProductCompany": "APO",
        "ProductName": "Gabapentin",
        "Mg": "600mg",
        "PackSizeMaster": "100's",
        "Qty": 2,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 2293366,
        "ProductCompany": "APO",
        "ProductName": "Gabapentin",
        "Mg": "800 mg",
        "PackSizeMaster": "100's",
        "Qty": 3,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2407124,
        "ProductCompany": "APO",
        "ProductName": "Gliclazide tab",
        "Mg": "60mg",
        "PackSizeMaster": "100's",
        "Qty": 64,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 441619,
        "ProductCompany": "APO",
        "ProductName": "HYDRALAZINE  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "May-26"
    },
    {
        "DinMaster": 2457865,
        "ProductCompany": "JAMP",
        "ProductName": "HYDRALAZINE  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 10,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2246691,
        "ProductCompany": "APO",
        "ProductName": "HYDROXYQUINE  TAB",
        "Mg": "200 mg",
        "PackSizeMaster": "100'S",
        "Qty": 10,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2327856,
        "ProductCompany": "APO",
        "ProductName": "HYDROCHLOROTHIAZIDE",
        "Mg": "12.5 mg",
        "PackSizeMaster": "500'S",
        "Qty": 9,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 441643,
        "ProductCompany": "APO",
        "ProductName": "IBUPROFEN  TAB",
        "Mg": "200 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 2,
        "Expiry": "Feb-27"
    },
    {
        "DinMaster": 585114,
        "ProductCompany": "APO",
        "ProductName": "IBUPROFEN  TAB",
        "Mg": "600 mg",
        "PackSizeMaster": "100'S",
        "Qty": 17,
        "Expiry": "Oct-27"
    },
    {
        "DinMaster": 506052,
        "ProductCompany": "APO",
        "ProductName": "IBUPROFEN  TAB",
        "Mg": "400 mg",
        "PackSizeMaster": "100'S",
        "Qty": 41,
        "Expiry": "Mar-28"
    },
    {
        "DinMaster": 2272830,
        "ProductCompany": "APO",
        "ProductName": "ISMN Tab",
        "Mg": "60mg",
        "PackSizeMaster": "100'S",
        "Qty": 5,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2229080,
        "ProductCompany": "APO",
        "ProductName": "KETOROLAC  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Jan-26"
    },
    {
        "DinMaster": 2237235,
        "ProductCompany": "APO",
        "ProductName": "Ketoconazole ",
        "Mg": "200mg",
        "PackSizeMaster": "100's",
        "Qty": 4,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2243538,
        "ProductCompany": "APO",
        "ProductName": "LABETALOL",
        "Mg": "100 mg",
        "PackSizeMaster": "100's",
        "Qty": 9,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2243539,
        "ProductCompany": "APO",
        "ProductName": "LABETALOL",
        "Mg": "200 mg",
        "PackSizeMaster": "100's",
        "Qty": 11,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2369052,
        "ProductCompany": "APO",
        "ProductName": "LAMIVUDINE",
        "Mg": "150MG",
        "PackSizeMaster": "60'S",
        "Qty": 2,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 2393239,
        "ProductCompany": "APO",
        "ProductName": "LAMIVUDINE HBV",
        "Mg": "100MG",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2195933,
        "ProductCompany": "APO",
        "ProductName": "LEVOCARB TAB",
        "Mg": "100\/10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2195941,
        "ProductCompany": "APO",
        "ProductName": "LEVOCARB  TAB",
        "Mg": "100\/25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 11,
        "Expiry": "Jul-25"
    },
    {
        "DinMaster": 2195968,
        "ProductCompany": "APO",
        "ProductName": "LEVOCARB TAB",
        "Mg": "250\/25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 18,
        "Expiry": "Dec-24"
    },
    {
        "DinMaster": 2284707,
        "ProductCompany": "APO",
        "ProductName": "Levofloxacin",
        "Mg": "250 mg",
        "PackSizeMaster": "100's",
        "Qty": 1,
        "Expiry": "Dec-24"
    },
    {
        "DinMaster": 2284715,
        "ProductCompany": "APO",
        "ProductName": "Levofloxacin",
        "Mg": "500 mg",
        "PackSizeMaster": "100's",
        "Qty": 0,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2242838,
        "ProductCompany": "APO",
        "ProductName": "LITHIUM CARBONATE  CAP",
        "Mg": "300 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2242837,
        "ProductCompany": "APO",
        "ProductName": "LITHIUM CARBONATE  CAP",
        "Mg": "150 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2243880,
        "ProductCompany": "APO",
        "ProductName": "LORATADINE  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 12,
        "Expiry": "Jan-26"
    },
    {
        "DinMaster": 2244726,
        "ProductCompany": "APO",
        "ProductName": "MEDROXY  TAB",
        "Mg": "2.5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 1,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2244727,
        "ProductCompany": "APO",
        "ProductName": "MEDROXY  TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 8,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2277298,
        "ProductCompany": "APO",
        "ProductName": "MEDROXY  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2248973,
        "ProductCompany": "APO",
        "ProductName": "Meloxicam Tab",
        "Mg": "7.5mg",
        "PackSizeMaster": "100's",
        "Qty": 2,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2248974,
        "ProductCompany": "APO",
        "ProductName": "Meloxicam Tab",
        "Mg": "15mg",
        "PackSizeMaster": "100's",
        "Qty": 4,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2246010,
        "ProductCompany": "APO",
        "ProductName": "METOPROLOL TAB",
        "Mg": "25 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 13,
        "Expiry": "Feb-27"
    },
    {
        "DinMaster": 618632,
        "ProductCompany": "APO",
        "ProductName": "METOPROLOL  TAB",
        "Mg": "50 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 10,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 618640,
        "ProductCompany": "APO",
        "ProductName": "METOPROLO TAB",
        "Mg": "100 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 6,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2278677,
        "ProductCompany": "APO",
        "ProductName": "MIDODRINE ",
        "Mg": "2.5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2278685,
        "ProductCompany": "APO",
        "ProductName": "MIDODRINE ",
        "Mg": "5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 9,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2286610,
        "ProductCompany": "APO",
        "ProductName": "MIRTAZAPINE ",
        "Mg": "15 mg",
        "PackSizeMaster": "30'S",
        "Qty": 60,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2411695,
        "ProductCompany": "AURO",
        "ProductName": "AURO- MIRTAZAPINE  TAB",
        "Mg": "15 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2286629,
        "ProductCompany": "APO",
        "ProductName": "MIRTAZAPINE   ",
        "Mg": "30 mg",
        "PackSizeMaster": "100'S",
        "Qty": 13,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2286637,
        "ProductCompany": "APO",
        "ProductName": "MIRTAZAPINE  ",
        "Mg": "45 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 2415380,
        "ProductCompany": "APO",
        "ProductName": "MYA",
        "Mg": "3mg\/0.02mg",
        "PackSizeMaster": "28's",
        "Qty": 10,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2352559,
        "ProductCompany": "APO",
        "ProductName": "MYCOPHENOLATE  CAP",
        "Mg": "250 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2352567,
        "ProductCompany": "APO",
        "ProductName": "MYCOPHENOLATE 500MG",
        "Mg": "500 mg",
        "PackSizeMaster": "50'S",
        "Qty": 0,
        "Expiry": "Mar-24"
    },
    {
        "DinMaster": 782505,
        "ProductCompany": "APO",
        "ProductName": "Nadolol Tab",
        "Mg": "40mg",
        "PackSizeMaster": "100's",
        "Qty": 5,
        "Expiry": "Dec-25"
    },
    {
        "DinMaster": 2444275,
        "ProductCompany": "APO",
        "ProductName": "Naltrexone Tab  40 ",
        "Mg": "50mg",
        "PackSizeMaster": "30's",
        "Qty": 0,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 2245058,
        "ProductCompany": "APO",
        "ProductName": "OMEPRAZOLE  CAP",
        "Mg": "20 mg",
        "PackSizeMaster": "100'S",
        "Qty": 80,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 2245058,
        "ProductCompany": "APO",
        "ProductName": "OMEPRAZOLE  CAP",
        "Mg": "20 mg",
        "PackSizeMaster": "500'S",
        "Qty": 6,
        "Expiry": "Mar-26"
    },
    {
        "DinMaster": 2292920,
        "ProductCompany": "APO",
        "ProductName": "PANTOPRAZOLE  TAB",
        "Mg": "40 mg",
        "PackSizeMaster": "500'S",
        "Qty": 24,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2240907,
        "ProductCompany": "APO",
        "ProductName": "PAROXETINE  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 12,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2240908,
        "ProductCompany": "APO",
        "ProductName": "PAROXETINE  TAB",
        "Mg": "20 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2240909,
        "ProductCompany": "APO",
        "ProductName": "PAROXETINE  TAB",
        "Mg": "30 mg",
        "PackSizeMaster": "100'S",
        "Qty": 8,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2302942,
        "ProductCompany": "APO",
        "ProductName": "PIOGLITAZONE TAB",
        "Mg": "15 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2302950,
        "ProductCompany": "APO",
        "ProductName": "PIOGLITAZONE  TAB",
        "Mg": "30 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2302977,
        "ProductCompany": "APO",
        "ProductName": "PIOGLITAZONE TAB",
        "Mg": "45 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 642886,
        "ProductCompany": "APO",
        "ProductName": "Piroxicam Cap",
        "Mg": "10mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "Nov-25"
    },
    {
        "DinMaster": 642894,
        "ProductCompany": "APO",
        "ProductName": "Piroxicam Cap",
        "Mg": "20mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "May-26"
    },
    {
        "DinMaster": 2292378,
        "ProductCompany": "APO",
        "ProductName": "PRAMIPEXOLE TAB",
        "Mg": "0.25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2292386,
        "ProductCompany": "APO",
        "ProductName": "PRAMIPEXOLE TAB",
        "Mg": "0.5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2292394,
        "ProductCompany": "APO",
        "ProductName": "PRAMIPEXOLE TAB",
        "Mg": "1 mg",
        "PackSizeMaster": "100'S",
        "Qty": 9,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 2243506,
        "ProductCompany": "APO",
        "ProductName": "PRAVASTATIN  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "30'S",
        "Qty": 14,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2243506,
        "ProductCompany": "APO",
        "ProductName": "PRAVASTATIN  TAB",
        "Mg": "10 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Mar-25"
    },
    {
        "DinMaster": 2243507,
        "ProductCompany": "APO",
        "ProductName": "PRAVASTATIN  TAB",
        "Mg": "20 mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Dec-24"
    },
    {
        "DinMaster": 2243508,
        "ProductCompany": "APO",
        "ProductName": "PRAVASTATIN  TAB",
        "Mg": "40 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Feb-25"
    },
    {
        "DinMaster": 312770,
        "ProductCompany": "APO",
        "ProductName": "PREDNISONE  TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "1000'S",
        "Qty": 0,
        "Expiry": "Dec-26"
    },
    {
        "DinMaster": 550957,
        "ProductCompany": "APO",
        "ProductName": "PREDNISONE  TAB",
        "Mg": "50 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Apr-26"
    },
    {
        "DinMaster": 21008,
        "ProductCompany": "TEVA",
        "ProductName": "QUININE CAP",
        "Mg": "200 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "Sep-25"
    },
    {
        "DinMaster": 2408783,
        "ProductCompany": "APO",
        "ProductName": "QUINAPRIL\/HCTZ",
        "Mg": "20\/25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Mar-23"
    },
    {
        "DinMaster": 2340585,
        "ProductCompany": "PMS",
        "ProductName": "QUINAPRIL TAB",
        "Mg": "40 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Feb-24"
    },
    {
        "DinMaster": 2325381,
        "ProductCompany": "APO",
        "ProductName": "Ramipril Cap",
        "Mg": "15mg ",
        "PackSizeMaster": "30's",
        "Qty": 0,
        "Expiry": "Mar-24"
    },
    {
        "DinMaster": 2325381,
        "ProductCompany": "APO",
        "ProductName": "Ramipril Cap",
        "Mg": "15mg ",
        "PackSizeMaster": "100's",
        "Qty": 9,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2302209,
        "ProductCompany": "PMS",
        "ProductName": "RISEDRONATE TAB",
        "Mg": "35 mg",
        "PackSizeMaster": "4'S",
        "Qty": 0,
        "Expiry": "Sep-23"
    },
    {
        "DinMaster": 2282119,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE TAB",
        "Mg": "0.25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 7,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2282127,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE TAB",
        "Mg": "0.5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Mar-26"
    },
    {
        "DinMaster": 2282135,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE  TAB",
        "Mg": "1 mg",
        "PackSizeMaster": "100'S",
        "Qty": 11,
        "Expiry": "Mar-26"
    },
    {
        "DinMaster": 2282143,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE  TAB",
        "Mg": "2 mg",
        "PackSizeMaster": "100'S\/500's",
        "Qty": 6,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2282151,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE  TAB",
        "Mg": "3 mg",
        "PackSizeMaster": "100'S",
        "Qty": 8,
        "Expiry": "Oct-25"
    },
    {
        "DinMaster": 2282178,
        "ProductCompany": "APO",
        "ProductName": "RISPERIDONE  TAB",
        "Mg": "4 mg",
        "PackSizeMaster": "100'S",
        "Qty": 11,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2393476,
        "ProductCompany": "APO",
        "ProductName": "Rizatriptan Tab",
        "Mg": "10mg",
        "PackSizeMaster": "6's",
        "Qty": 3,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2210428,
        "ProductCompany": "APO",
        "ProductName": "Sotalol Tab",
        "Mg": "80mg",
        "PackSizeMaster": "100'S",
        "Qty": 6,
        "Expiry": "Dec-25"
    },
    {
        "DinMaster": 2238280,
        "ProductCompany": "APO",
        "ProductName": "SERTRALINE CAP  Jan 25th)",
        "Mg": "25 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "Oct-26"
    },
    {
        "DinMaster": 2238282,
        "ProductCompany": "APO",
        "ProductName": "SERTRALINE CAP",
        "Mg": "100 mg",
        "PackSizeMaster": "100'S",
        "Qty": 21,
        "Expiry": "Oct-26"
    },
    {
        "DinMaster": 2238281,
        "ProductCompany": "APO",
        "ProductName": "SERTRALINE CAP",
        "Mg": "50 mg",
        "PackSizeMaster": "100'S",
        "Qty": 0,
        "Expiry": "May-26"
    },
    {
        "DinMaster": 2238281,
        "ProductCompany": "APO",
        "ProductName": "SERTRALINE CAP",
        "Mg": "50 mg",
        "PackSizeMaster": "250'S",
        "Qty": 20,
        "Expiry": "Nov-26"
    },
    {
        "DinMaster": 2248203,
        "ProductCompany": "APO",
        "ProductName": "Sildenafil Tab",
        "Mg": "100mg",
        "PackSizeMaster": "4's",
        "Qty": 12,
        "Expiry": "Nov-26"
    },
    {
        "DinMaster": 2508656,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate",
        "Mg": "25mg",
        "PackSizeMaster": "100's",
        "Qty": 26,
        "Expiry": "Oct 25"
    },
    {
        "DinMaster": 2508664,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate",
        "Mg": "50mg",
        "PackSizeMaster": "30's",
        "Qty": 0,
        "Expiry": "Nov 24"
    },
    {
        "DinMaster": 2508664,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate",
        "Mg": "50mg",
        "PackSizeMaster": "100's",
        "Qty": 19,
        "Expiry": "Oct 25"
    },
    {
        "DinMaster": 2508672,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate",
        "Mg": "100mg",
        "PackSizeMaster": "30's",
        "Qty": 0,
        "Expiry": "Feb 25"
    },
    {
        "DinMaster": 2508672,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate",
        "Mg": "100mg",
        "PackSizeMaster": "100's",
        "Qty": 17,
        "Expiry": "Feb 25"
    },
    {
        "DinMaster": 2503956,
        "ProductCompany": "SDZ",
        "ProductName": "Sitagliptin Malate\/Metformin ",
        "Mg": "50\/500 mg",
        "PackSizeMaster": "100's",
        "Qty": 36,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2509415,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate\/Metformin",
        "Mg": "50mg\/500mg",
        "PackSizeMaster": "100's",
        "Qty": 4,
        "Expiry": "Nov 24"
    },
    {
        "DinMaster": 2503964,
        "ProductCompany": "SDZ",
        "ProductName": "Sitagliptin Malate\/Metformin ",
        "Mg": "50\/850 mg",
        "PackSizeMaster": "100's",
        "Qty": 0
    },
    {
        "DinMaster": 2509423,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate\/Metformin ",
        "Mg": "50\/850 mg",
        "PackSizeMaster": "100's",
        "Qty": 0
    },
    {
        "DinMaster": 2529114,
        "ProductCompany": "SDZ",
        "ProductName": "Sitagliptin Malate\/Metformin XR",
        "Mg": "50\/1000 mg",
        "PackSizeMaster": "60's",
        "Qty": 8
    },
    {
        "DinMaster": 2506289,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin\/Metformin Hydro XR",
        "Mg": "50\/1000mg ",
        "PackSizeMaster": 100,
        "Qty": 0,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2503972,
        "ProductCompany": "SANDOZ",
        "ProductName": "Sitagliptin-Metformin Tab",
        "Mg": "50mg\/1000mg",
        "PackSizeMaster": "60's",
        "Qty": 14,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2509431,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate\/Metformin Hydro",
        "Mg": "50\/1000mg ",
        "PackSizeMaster": "100's",
        "Qty": 1,
        "Expiry": "Oct-24"
    },
    {
        "DinMaster": 2529106,
        "ProductCompany": "SDZ",
        "ProductName": "Sitagliptin Malate\/Metformin XR",
        "Mg": "50\/500 mg",
        "PackSizeMaster": "60's",
        "Qty": 5
    },
    {
        "DinMaster": 2529122,
        "ProductCompany": "SDZ",
        "ProductName": "Sitagliptin Malate\/Metformin XR",
        "Mg": "100\/1000 mg",
        "Qty": 24
    },
    {
        "DinMaster": 2506297,
        "ProductCompany": "APO",
        "ProductName": "Sitagliptin Malate\/Metformin XR",
        "Mg": "100\/1000 mg",
        "PackSizeMaster": "30's",
        "Qty": 4
    },
    {
        "DinMaster": 812390,
        "ProductCompany": "APO",
        "ProductName": "TAMOXIFEN TAB",
        "Mg": "20 mg",
        "PackSizeMaster": "100'S",
        "Qty": 3,
        "Expiry": "Jul-25"
    },
    {
        "DinMaster": 2234502,
        "ProductCompany": "APO",
        "ProductName": "TERAZOSIN  TAB",
        "Mg": "1 mg",
        "PackSizeMaster": "100'S",
        "Qty": 5,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2234503,
        "ProductCompany": "APO",
        "ProductName": "TERAZOSIN  TAB",
        "Mg": "2 mg",
        "PackSizeMaster": "100'S",
        "Qty": 8,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2234504,
        "ProductCompany": "APO",
        "ProductName": "TERAZOSIN  TAB",
        "Mg": "5 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2147653,
        "ProductCompany": "APO",
        "ProductName": "TRAZODONE tab",
        "Mg": "150 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Mar-26"
    },
    {
        "DinMaster": 2415305,
        "ProductCompany": "APO",
        "ProductName": "Travoprost-Timop PQ  (Single Source)",
        "Mg": "0.004%0.5%",
        "PackSizeMaster": "5 ml",
        "Qty": 13,
        "Expiry": "Jul-25"
    },
    {
        "DinMaster": 755834,
        "ProductCompany": "APO",
        "ProductName": "Timop OPH Sol  ",
        "Mg": 0.005,
        "PackSizeMaster": "10 ml",
        "Qty": 8,
        "Expiry": "Sep-24"
    },
    {
        "DinMaster": 2508095,
        "ProductCompany": "APO",
        "ProductName": "Tri-Cira 28",
        "Mg": "0.25\/0.035mg",
        "PackSizeMaster": "28's",
        "Qty": 6,
        "Expiry": "Jun-25"
    },
    {
        "DinMaster": 2401975,
        "ProductCompany": "APO",
        "ProductName": "TRI-CIRA LO 28",
        "PackSizeMaster": "28'S",
        "Qty": 15,
        "Expiry": "May-25"
    },
    {
        "DinMaster": 2435675,
        "ProductCompany": "APO",
        "ProductName": "VARENICLINE TAB",
        "Mg": "0.5\/1 mg",
        "PackSizeMaster": "53'S",
        "Qty": 0
    },
    {
        "DinMaster": 2419890,
        "ProductCompany": "APO",
        "ProductName": "VARENICLINE TAB",
        "Mg": "1 mg",
        "PackSizeMaster": "56'S",
        "Qty": 5,
        "Expiry": "Apr-25"
    },
    {
        "DinMaster": 2419882,
        "ProductCompany": "APO",
        "ProductName": "VARENICLINE TAB",
        "Mg": "0.5 mg",
        "PackSizeMaster": "56'S",
        "Qty": 12,
        "Expiry": "May-24"
    },
    {
        "DinMaster": 782483,
        "ProductCompany": "APO",
        "ProductName": "VERAP TAB",
        "Mg": "80 mg",
        "PackSizeMaster": "100'S",
        "Qty": 4,
        "Expiry": "Mar-27"
    },
    {
        "DinMaster": 782491,
        "ProductCompany": "APO",
        "ProductName": "VERAP TAB",
        "Mg": "120 mg",
        "PackSizeMaster": "100'S",
        "Qty": 2,
        "Expiry": "Jan-26"
    },
    {
        "DinMaster": 2242929,
        "ProductCompany": "APO",
        "ProductName": "Warfarin Tab",
        "Mg": "1mg",
        "PackSizeMaster": "100's",
        "Qty": 4,
        "Expiry": "Aug-25"
    },
    {
        "DinMaster": 2242925,
        "ProductCompany": "APO",
        "ProductName": "Warfarin Tab",
        "Mg": "2mg",
        "PackSizeMaster": "100's",
        "Qty": 4,
        "Expiry": "Jan-25"
    },
    {
        "DinMaster": 2410796,
        "ProductCompany": "APO",
        "ProductName": "Zamine",
        "Mg": "3.0\/0.03mg",
        "PackSizeMaster": "28's",
        "Qty": 6,
        "Expiry": "Apr-25"
    },

]

// export const productsList = [
//     {
//         "DinMaster": 2396769,
//         "ProductCompany": "APO",
//         "ProductName": "ABACAVIR",
//         "Mg": "300mg",
//         "PackSizeMaster": "60's",
//     },
//     {
//         "DinMaster": 2229977,
//         "ProductCompany": "APO",
//         "ProductName": "ACETAMINOPHEN CAP",
//         "Mg": "500 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "ProductCompany": "APO",
//         "ProductName": "ACETAMINOPHEN ",
//         "Mg": "325mg",
//         "PackSizeMaster": "1000's",
//     },
//     {
//         "DinMaster": 2229873,
//         "ProductCompany": "APO",
//         "ProductName": "ACETAMINOPHEN CAP",
//         "Mg": "325 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 2402769,
//         "ProductCompany": "APO",
//         "ProductName": "ALLOPURINOL TAB",
//         "Mg": "100 mg",
//         "PackSizeMaster": "100's\/1000's",
//     },
//     {
//         "DinMaster": 2402777,
//         "ProductCompany": "APO",
//         "ProductName": "ALLOPURINOL TAB",
//         "Mg": "200 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2402785,
//         "ProductCompany": "APO",
//         "ProductName": "ALLOPURINOL TAB",
//         "Mg": "300 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2402785,
//         "ProductCompany": "APO",
//         "ProductName": "ALLOPURINOL TAB",
//         "Mg": "300 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2315866,
//         "ProductCompany": "APO",
//         "ProductName": "Alfuzosin Tab",
//         "Mg": "10mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2387875,
//         "ProductCompany": "APO",
//         "ProductName": "Alysena 21 Tab",
//         "Mg": "100mcg\/20mcg",
//         "PackSizeMaster": "21's",
//     },
//     {
//         "DinMaster": 2387883,
//         "ProductCompany": "APO",
//         "ProductName": "Alysena 28 Tab",
//         "Mg": "100mcg\/20mcg",
//         "PackSizeMaster": "28's",
//     },
//     {
//         "DinMaster": 2246194,
//         "ProductCompany": "APO",
//         "ProductName": "Amiodarone",
//         "Mg": "200mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2403161,
//         "ProductCompany": "APO",
//         "ProductName": "AMITRIPTYLINE TAB",
//         "Mg": "75 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2273373,
//         "ProductCompany": "APO",
//         "ProductName": "AMLODIPINE TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2273381,
//         "ProductCompany": "APO",
//         "ProductName": "AMLODIPINE TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 628131,
//         "ProductCompany": "APO",
//         "ProductName": "Amoxi Susp",
//         "Mg": "125mg\/5ml",
//         "PackSizeMaster": "100ml",
//     },
//     {
//         "DinMaster": 628131,
//         "ProductCompany": "APO",
//         "ProductName": "Amoxi Susp",
//         "Mg": "125mg\/5ml",
//         "PackSizeMaster": "150ml",
//     },
//     {
//         "DinMaster": 324019,
//         "ProductCompany": "APO",
//         "ProductName": "Anafranil Tab",
//         "Mg": "25mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2471086,
//         "ProductCompany": "APO",
//         "ProductName": "ARIPIPRAZOLE TAB  ",
//         "Mg": "2 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2471094,
//         "ProductCompany": "APO",
//         "ProductName": "ARIPIPRAZOLE TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2471108,
//         "ProductCompany": "APO",
//         "ProductName": "ARIPIPRAZOLE TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2471116,
//         "ProductCompany": "APO",
//         "ProductName": "ARIPIPRAZOLE TAB",
//         "Mg": "15 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2471124,
//         "ProductCompany": "APO",
//         "ProductName": "ARIPIPRAZOLE TAB",
//         "Mg": "20 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2295261,
//         "ProductCompany": "APO",
//         "ProductName": "ATORVASTATIN TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2295288,
//         "ProductCompany": "APO",
//         "ProductName": "ATORVASTATIN TAB",
//         "Mg": "20 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2295296,
//         "ProductCompany": "APO",
//         "ProductName": "ATORVASTATIN TAB",
//         "Mg": "40 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2295318,
//         "ProductCompany": "APO",
//         "ProductName": "ATORVASTATIN TAB",
//         "Mg": "80 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2256134,
//         "ProductCompany": "APO",
//         "ProductName": "BISOPROLOL TAB",
//         "Mg": "5mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2256177,
//         "ProductCompany": "APO",
//         "ProductName": "BISOPROLOL TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2375311,
//         "ProductCompany": "APO",
//         "ProductName": "Brimonidine-Timol",
//         "Mg": "0.2%\/0.5%",
//         "PackSizeMaster": "10ml",
//     },
//     {
//         "DinMaster": 2455897,
//         "ProductCompany": "APO",
//         "ProductName": "Cabergoline Tab",
//         "Mg": "0.5mg",
//         "PackSizeMaster": "8's",
//     },
//     {
//         "DinMaster": 2240774,
//         "ProductCompany": "APO",
//         "ProductName": "Cefadroxil cap",
//         "Mg": "500mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2274752,
//         "ProductCompany": "APO",
//         "ProductName": "CLARITHROMYCIN TAB",
//         "Mg": "500 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2413345,
//         "ProductCompany": "APO",
//         "ProductName": "CLARITHROMYCIN XL TAB",
//         "Mg": "500 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2252767,
//         "ProductCompany": "APO",
//         "ProductName": "CLOPIDOGREL TAB",
//         "Mg": "75 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2284030,
//         "ProductCompany": "APO",
//         "ProductName": "Desmopressin Tab",
//         "Mg": "0.1 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2284049,
//         "ProductCompany": "APO",
//         "ProductName": "Desmopressin Tab",
//         "Mg": "0.2 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 839175,
//         "ProductCompany": "APO",
//         "ProductName": "DICLOFENAC NA  TAB",
//         "Mg": "25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 839183,
//         "ProductCompany": "APO",
//         "ProductName": "DICLO 50mg  TAB",
//         "Mg": "50 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2302624,
//         "ProductCompany": "PMS",
//         "ProductName": "PMS-DICLOFENAC NA  TAB",
//         "Mg": "50 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2162814,
//         "ProductCompany": "APO",
//         "ProductName": "DICLOFENAC NA  SR TAB",
//         "Mg": "75 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2091194,
//         "ProductCompany": "APO",
//         "ProductName": "DICLOFENAC NA  SR TAB",
//         "Mg": "100 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2230997,
//         "ProductCompany": "APO",
//         "ProductName": "Diltiaz CD",
//         "Mg": "120mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2230998,
//         "ProductCompany": "APO",
//         "ProductName": "Diltiaz CD",
//         "Mg": "180mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2230999,
//         "ProductCompany": "APO",
//         "ProductName": "Diltiaz CD",
//         "Mg": "240mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2242540,
//         "ProductCompany": "TEVA",
//         "ProductName": "Diltiazem CD Cap",
//         "Mg": "240mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2229526,
//         "ProductCompany": "APO",
//         "ProductName": "Diltiaz CD",
//         "Mg": "300mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2362260,
//         "ProductCompany": "APO",
//         "ProductName": "DONEPEZIL  TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2362279,
//         "ProductCompany": "APO",
//         "ProductName": "DONEPEZIL  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2240588,
//         "ProductCompany": "APO",
//         "ProductName": "Doxazosin Tab",
//         "Mg": "1mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 740713,
//         "ProductCompany": "APO",
//         "ProductName": "Doxy",
//         "Mg": "100mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2413248,
//         "ProductCompany": "APO",
//         "ProductName": "DOXYLAMIN-PYRIDOXINE",
//         "Mg": "10 mg\/10mg",
//         "PackSizeMaster": "500's",
//     },
//     {
//         "DinMaster": 2404206,
//         "ProductCompany": "APO",
//         "ProductName": "Dutasteride Cap",
//         "Mg": "0.5 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2452006,
//         "ProductCompany": "APO",
//         "ProductName": "Emtricitabin-Tenovir Tab",
//         "Mg": "200mg\/300mg",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 2292041,
//         "ProductCompany": "APO",
//         "ProductName": "FAMCICLOVIR  TAB",
//         "Mg": "250 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2292068,
//         "ProductCompany": "APO",
//         "ProductName": "FAMCICLOVIR  TAB",
//         "Mg": "500 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2452367,
//         "ProductCompany": "APO",
//         "ProductName": "Feldipine Tab",
//         "Mg": "2.5 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2237370,
//         "ProductCompany": "APO",
//         "ProductName": "Fluconazole Tab",
//         "Mg": "50mg",
//         "PackSizeMaster": "50's",
//     },
//     {
//         "DinMaster": 2245292,
//         "ProductCompany": "MYLAN",
//         "ProductName": "Fluconazole Tab",
//         "Mg": "50mg",
//         "PackSizeMaster": "50's",
//     },
//     {
//         "DinMaster": 2237371,
//         "ProductCompany": "APO",
//         "ProductName": "Fluconazole Tab",
//         "Mg": "100mg",
//         "PackSizeMaster": "50's",
//     },
//     {
//         "DinMaster": 2245293,
//         "ProductCompany": "MYLAN",
//         "ProductName": "Fluconazole Tab",
//         "Mg": "100mg",
//         "PackSizeMaster": "50's",
//     },
//     {
//         "DinMaster": 2241895,
//         "ProductCompany": "APO",
//         "ProductName": "FLUCONAZOLE  CAP",
//         "Mg": "150 mg",
//         "PackSizeMaster": "1'S",
//     },
//     {
//         "DinMaster": 2294745,
//         "ProductCompany": "APO",
//         "ProductName": "FLUTICASONE SPRAY",
//         "Mg": "50mcg",
//         "PackSizeMaster": "120 ML",
//     },
//     {
//         "DinMaster": 2526557,
//         "ProductCompany": "APO",
//         "ProductName": "FLUTICASONE HFA",
//         "Mg": "125 mcg",
//         "PackSizeMaster": "120 METERED",
//     },
//     {
//         "DinMaster": 2510987,
//         "ProductCompany": "APO",
//         "ProductName": "FLUTICASONE HFA",
//         "Mg": "250 mcg",
//         "PackSizeMaster": "120 METERED",
//     },
//     {
//         "DinMaster": 2231328,
//         "ProductCompany": "APO",
//         "ProductName": "Fluoxetine Sol",
//         "Mg": "20mg\/5ml",
//         "PackSizeMaster": "120 ML",
//     },
//     {
//         "DinMaster": 2231329,
//         "ProductCompany": "APO",
//         "ProductName": "FLUVOXAMINE  TAB",
//         "Mg": "50 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2231330,
//         "ProductCompany": "APO",
//         "ProductName": "FLUVOXAMINE  TAB",
//         "Mg": "100 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2266016,
//         "ProductCompany": "APO",
//         "ProductName": "Fosinopril",
//         "Mg": "20mg ",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2293358,
//         "ProductCompany": "APO",
//         "ProductName": "Gabapentin",
//         "Mg": "600mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2293366,
//         "ProductCompany": "APO",
//         "ProductName": "Gabapentin",
//         "Mg": "800 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2407124,
//         "ProductCompany": "APO",
//         "ProductName": "Gliclazide tab",
//         "Mg": "60mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 441619,
//         "ProductCompany": "APO",
//         "ProductName": "HYDRALAZINE  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2457865,
//         "ProductCompany": "JAMP",
//         "ProductName": "HYDRALAZINE  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2246691,
//         "ProductCompany": "APO",
//         "ProductName": "HYDROXYQUINE  TAB",
//         "Mg": "200 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2327856,
//         "ProductCompany": "APO",
//         "ProductName": "HYDROCHLOROTHIAZIDE",
//         "Mg": "12.5 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 441643,
//         "ProductCompany": "APO",
//         "ProductName": "IBUPROFEN  TAB",
//         "Mg": "200 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 585114,
//         "ProductCompany": "APO",
//         "ProductName": "IBUPROFEN  TAB",
//         "Mg": "600 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 506052,
//         "ProductCompany": "APO",
//         "ProductName": "IBUPROFEN  TAB",
//         "Mg": "400 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2272830,
//         "ProductCompany": "APO",
//         "ProductName": "ISMN Tab",
//         "Mg": "60mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2229080,
//         "ProductCompany": "APO",
//         "ProductName": "KETOROLAC  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2237235,
//         "ProductCompany": "APO",
//         "ProductName": "Ketoconazole ",
//         "Mg": "200mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2243538,
//         "ProductCompany": "APO",
//         "ProductName": "LABETALOL",
//         "Mg": "100 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2243539,
//         "ProductCompany": "APO",
//         "ProductName": "LABETALOL",
//         "Mg": "200 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2369052,
//         "ProductCompany": "APO",
//         "ProductName": "LAMIVUDINE",
//         "Mg": "150MG",
//         "PackSizeMaster": "60'S",
//     },
//     {
//         "DinMaster": 2393239,
//         "ProductCompany": "APO",
//         "ProductName": "LAMIVUDINE HBV",
//         "Mg": "100MG",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2195933,
//         "ProductCompany": "APO",
//         "ProductName": "LEVOCARB TAB",
//         "Mg": "100\/10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2195941,
//         "ProductCompany": "APO",
//         "ProductName": "LEVOCARB  TAB",
//         "Mg": "100\/25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2195968,
//         "ProductCompany": "APO",
//         "ProductName": "LEVOCARB TAB",
//         "Mg": "250\/25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2284707,
//         "ProductCompany": "APO",
//         "ProductName": "Levofloxacin",
//         "Mg": "250 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2284715,
//         "ProductCompany": "APO",
//         "ProductName": "Levofloxacin",
//         "Mg": "500 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2242838,
//         "ProductCompany": "APO",
//         "ProductName": "LITHIUM CARBONATE  CAP",
//         "Mg": "300 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2242837,
//         "ProductCompany": "APO",
//         "ProductName": "LITHIUM CARBONATE  CAP",
//         "Mg": "150 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2243880,
//         "ProductCompany": "APO",
//         "ProductName": "LORATADINE  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2244726,
//         "ProductCompany": "APO",
//         "ProductName": "MEDROXY  TAB",
//         "Mg": "2.5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2244727,
//         "ProductCompany": "APO",
//         "ProductName": "MEDROXY  TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2277298,
//         "ProductCompany": "APO",
//         "ProductName": "MEDROXY  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2248973,
//         "ProductCompany": "APO",
//         "ProductName": "Meloxicam Tab",
//         "Mg": "7.5mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2248974,
//         "ProductCompany": "APO",
//         "ProductName": "Meloxicam Tab",
//         "Mg": "15mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2246010,
//         "ProductCompany": "APO",
//         "ProductName": "METOPROLOL TAB",
//         "Mg": "25 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 618632,
//         "ProductCompany": "APO",
//         "ProductName": "METOPROLOL  TAB",
//         "Mg": "50 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 618640,
//         "ProductCompany": "APO",
//         "ProductName": "METOPROLO TAB",
//         "Mg": "100 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 2278677,
//         "ProductCompany": "APO",
//         "ProductName": "MIDODRINE ",
//         "Mg": "2.5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2278685,
//         "ProductCompany": "APO",
//         "ProductName": "MIDODRINE ",
//         "Mg": "5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2286610,
//         "ProductCompany": "APO",
//         "ProductName": "MIRTAZAPINE ",
//         "Mg": "15 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2411695,
//         "ProductCompany": "AURO",
//         "ProductName": "AURO- MIRTAZAPINE  TAB",
//         "Mg": "15 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2286629,
//         "ProductCompany": "APO",
//         "ProductName": "MIRTAZAPINE   ",
//         "Mg": "30 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2286637,
//         "ProductCompany": "APO",
//         "ProductName": "MIRTAZAPINE  ",
//         "Mg": "45 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2415380,
//         "ProductCompany": "APO",
//         "ProductName": "MYA",
//         "Mg": "3mg\/0.02mg",
//         "PackSizeMaster": "28's",
//     },
//     {
//         "DinMaster": 2352559,
//         "ProductCompany": "APO",
//         "ProductName": "MYCOPHENOLATE  CAP",
//         "Mg": "250 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2352567,
//         "ProductCompany": "APO",
//         "ProductName": "MYCOPHENOLATE 500MG",
//         "Mg": "500 mg",
//         "PackSizeMaster": "50'S",
//     },
//     {
//         "DinMaster": 782505,
//         "ProductCompany": "APO",
//         "ProductName": "Nadolol Tab",
//         "Mg": "40mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2444275,
//         "ProductCompany": "APO",
//         "ProductName": "Naltrexone Tab  40 ",
//         "Mg": "50mg",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 2245058,
//         "ProductCompany": "APO",
//         "ProductName": "OMEPRAZOLE  CAP",
//         "Mg": "20 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2245058,
//         "ProductCompany": "APO",
//         "ProductName": "OMEPRAZOLE  CAP",
//         "Mg": "20 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2292920,
//         "ProductCompany": "APO",
//         "ProductName": "PANTOPRAZOLE  TAB",
//         "Mg": "40 mg",
//         "PackSizeMaster": "500'S",
//     },
//     {
//         "DinMaster": 2240907,
//         "ProductCompany": "APO",
//         "ProductName": "PAROXETINE  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2240908,
//         "ProductCompany": "APO",
//         "ProductName": "PAROXETINE  TAB",
//         "Mg": "20 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2240909,
//         "ProductCompany": "APO",
//         "ProductName": "PAROXETINE  TAB",
//         "Mg": "30 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2302942,
//         "ProductCompany": "APO",
//         "ProductName": "PIOGLITAZONE TAB",
//         "Mg": "15 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2302950,
//         "ProductCompany": "APO",
//         "ProductName": "PIOGLITAZONE  TAB",
//         "Mg": "30 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2302977,
//         "ProductCompany": "APO",
//         "ProductName": "PIOGLITAZONE TAB",
//         "Mg": "45 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 642886,
//         "ProductCompany": "APO",
//         "ProductName": "Piroxicam Cap",
//         "Mg": "10mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 642894,
//         "ProductCompany": "APO",
//         "ProductName": "Piroxicam Cap",
//         "Mg": "20mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2292378,
//         "ProductCompany": "APO",
//         "ProductName": "PRAMIPEXOLE TAB",
//         "Mg": "0.25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2292386,
//         "ProductCompany": "APO",
//         "ProductName": "PRAMIPEXOLE TAB",
//         "Mg": "0.5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2292394,
//         "ProductCompany": "APO",
//         "ProductName": "PRAMIPEXOLE TAB",
//         "Mg": "1 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2243506,
//         "ProductCompany": "APO",
//         "ProductName": "PRAVASTATIN  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "30'S",
//     },
//     {
//         "DinMaster": 2243506,
//         "ProductCompany": "APO",
//         "ProductName": "PRAVASTATIN  TAB",
//         "Mg": "10 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2243507,
//         "ProductCompany": "APO",
//         "ProductName": "PRAVASTATIN  TAB",
//         "Mg": "20 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2243508,
//         "ProductCompany": "APO",
//         "ProductName": "PRAVASTATIN  TAB",
//         "Mg": "40 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 312770,
//         "ProductCompany": "APO",
//         "ProductName": "PREDNISONE  TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "1000'S",
//     },
//     {
//         "DinMaster": 550957,
//         "ProductCompany": "APO",
//         "ProductName": "PREDNISONE  TAB",
//         "Mg": "50 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 21008,
//         "ProductCompany": "TEVA",
//         "ProductName": "QUININE CAP",
//         "Mg": "200 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2408783,
//         "ProductCompany": "APO",
//         "ProductName": "QUINAPRIL\/HCTZ",
//         "Mg": "20\/25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2340585,
//         "ProductCompany": "PMS",
//         "ProductName": "QUINAPRIL TAB",
//         "Mg": "40 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2325381,
//         "ProductCompany": "APO",
//         "ProductName": "Ramipril Cap",
//         "Mg": "15mg ",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 2325381,
//         "ProductCompany": "APO",
//         "ProductName": "Ramipril Cap",
//         "Mg": "15mg ",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2302209,
//         "ProductCompany": "PMS",
//         "ProductName": "RISEDRONATE TAB",
//         "Mg": "35 mg",
//         "PackSizeMaster": "4'S",
//     },
//     {
//         "DinMaster": 2282119,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE TAB",
//         "Mg": "0.25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2282127,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE TAB",
//         "Mg": "0.5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2282135,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE  TAB",
//         "Mg": "1 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2282143,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE  TAB",
//         "Mg": "2 mg",
//         "PackSizeMaster": "100'S\/500's",
//     },
//     {
//         "DinMaster": 2282151,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE  TAB",
//         "Mg": "3 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2282178,
//         "ProductCompany": "APO",
//         "ProductName": "RISPERIDONE  TAB",
//         "Mg": "4 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2393476,
//         "ProductCompany": "APO",
//         "ProductName": "Rizatriptan Tab",
//         "Mg": "10mg",
//         "PackSizeMaster": "6's",
//     },
//     {
//         "DinMaster": 2210428,
//         "ProductCompany": "APO",
//         "ProductName": "Sotalol Tab",
//         "Mg": "80mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2238280,
//         "ProductCompany": "APO",
//         "ProductName": "SERTRALINE CAP  Jan 25th)",
//         "Mg": "25 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2238282,
//         "ProductCompany": "APO",
//         "ProductName": "SERTRALINE CAP",
//         "Mg": "100 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2238281,
//         "ProductCompany": "APO",
//         "ProductName": "SERTRALINE CAP",
//         "Mg": "50 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2238281,
//         "ProductCompany": "APO",
//         "ProductName": "SERTRALINE CAP",
//         "Mg": "50 mg",
//         "PackSizeMaster": "250'S",
//     },
//     {
//         "DinMaster": 2248203,
//         "ProductCompany": "APO",
//         "ProductName": "Sildenafil Tab",
//         "Mg": "100mg",
//         "PackSizeMaster": "4's",
//     },
//     {
//         "DinMaster": 2508656,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate",
//         "Mg": "25mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2508664,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate",
//         "Mg": "50mg",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 2508664,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate",
//         "Mg": "50mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2508672,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate",
//         "Mg": "100mg",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 2508672,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate",
//         "Mg": "100mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2503956,
//         "ProductCompany": "SDZ",
//         "ProductName": "Sitagliptin Malate\/Metformin ",
//         "Mg": "50\/500 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2509415,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate\/Metformin",
//         "Mg": "50mg\/500mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2503964,
//         "ProductCompany": "SDZ",
//         "ProductName": "Sitagliptin Malate\/Metformin ",
//         "Mg": "50\/850 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2509423,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate\/Metformin ",
//         "Mg": "50\/850 mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2529114,
//         "ProductCompany": "SDZ",
//         "ProductName": "Sitagliptin Malate\/Metformin XR",
//         "Mg": "50\/1000 mg",
//         "PackSizeMaster": "60's",
//     },
//     {
//         "DinMaster": 2506289,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin\/Metformin Hydro XR",
//         "Mg": "50\/1000mg ",
//         "PackSizeMaster": 100,
//     },
//     {
//         "DinMaster": 2503972,
//         "ProductCompany": "SANDOZ",
//         "ProductName": "Sitagliptin-Metformin Tab",
//         "Mg": "50mg\/1000mg",
//         "PackSizeMaster": "60's",
//     },
//     {
//         "DinMaster": 2509431,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate\/Metformin Hydro",
//         "Mg": "50\/1000mg ",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2529106,
//         "ProductCompany": "SDZ",
//         "ProductName": "Sitagliptin Malate\/Metformin XR",
//         "Mg": "50\/500 mg",
//         "PackSizeMaster": "60's",
//     },
//     {
//         "DinMaster": 2529122,
//         "ProductCompany": "SDZ",
//         "ProductName": "Sitagliptin Malate\/Metformin XR",
//         "Mg": "100\/1000 mg",
//     },
//     {
//         "DinMaster": 2506297,
//         "ProductCompany": "APO",
//         "ProductName": "Sitagliptin Malate\/Metformin XR",
//         "Mg": "100\/1000 mg",
//         "PackSizeMaster": "30's",
//     },
//     {
//         "DinMaster": 812390,
//         "ProductCompany": "APO",
//         "ProductName": "TAMOXIFEN TAB",
//         "Mg": "20 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2234502,
//         "ProductCompany": "APO",
//         "ProductName": "TERAZOSIN  TAB",
//         "Mg": "1 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2234503,
//         "ProductCompany": "APO",
//         "ProductName": "TERAZOSIN  TAB",
//         "Mg": "2 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2234504,
//         "ProductCompany": "APO",
//         "ProductName": "TERAZOSIN  TAB",
//         "Mg": "5 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2147653,
//         "ProductCompany": "APO",
//         "ProductName": "TRAZODONE tab",
//         "Mg": "150 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2415305,
//         "ProductCompany": "APO",
//         "ProductName": "Travoprost-Timop PQ  (Single Source)",
//         "Mg": "0.004%0.5%",
//         "PackSizeMaster": "5 ml",
//     },
//     {
//         "DinMaster": 755834,
//         "ProductCompany": "APO",
//         "ProductName": "Timop OPH Sol  ",
//         "Mg": 0.005,
//         "PackSizeMaster": "10 ml",
//     },
//     {
//         "DinMaster": 2508095,
//         "ProductCompany": "APO",
//         "ProductName": "Tri-Cira 28",
//         "Mg": "0.25\/0.035mg",
//         "PackSizeMaster": "28's",
//     },
//     {
//         "DinMaster": 2401975,
//         "ProductCompany": "APO",
//         "ProductName": "TRI-CIRA LO 28",
//         "PackSizeMaster": "28'S",
//     },
//     {
//         "DinMaster": 2435675,
//         "ProductCompany": "APO",
//         "ProductName": "VARENICLINE TAB",
//         "Mg": "0.5\/1 mg",
//         "PackSizeMaster": "53'S",
//     },
//     {
//         "DinMaster": 2419890,
//         "ProductCompany": "APO",
//         "ProductName": "VARENICLINE TAB",
//         "Mg": "1 mg",
//         "PackSizeMaster": "56'S",
//     },
//     {
//         "DinMaster": 2419882,
//         "ProductCompany": "APO",
//         "ProductName": "VARENICLINE TAB",
//         "Mg": "0.5 mg",
//         "PackSizeMaster": "56'S",
//     },
//     {
//         "DinMaster": 782483,
//         "ProductCompany": "APO",
//         "ProductName": "VERAP TAB",
//         "Mg": "80 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 782491,
//         "ProductCompany": "APO",
//         "ProductName": "VERAP TAB",
//         "Mg": "120 mg",
//         "PackSizeMaster": "100'S",
//     },
//     {
//         "DinMaster": 2242929,
//         "ProductCompany": "APO",
//         "ProductName": "Warfarin Tab",
//         "Mg": "1mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2242925,
//         "ProductCompany": "APO",
//         "ProductName": "Warfarin Tab",
//         "Mg": "2mg",
//         "PackSizeMaster": "100's",
//     },
//     {
//         "DinMaster": 2410796,
//         "ProductCompany": "APO",
//         "ProductName": "Zamine",
//         "Mg": "3.0\/0.03mg",
//         "PackSizeMaster": "28's",
//     },

// ]